import {
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,

    RENTAL_ACTION_TIMEOUT,
    RENTAL_ACTION_RESERVE,
    RENTAL_ACTION_CANCEL,
    RENTAL_ACTION_BEEP,
    RENTAL_ACTION_RIDE,
    RENTAL_ACTION_PARK,
    RENTAL_ACTION_OPEN_TRUNK,
    RENTAL_ACTION_RETURN,

    RENTAL_SIMPLIFIED_IDLE,
    RENTAL_SIMPLIFIED_RESERVING,
    RENTAL_SIMPLIFIED_RENTING,

    RENTAL_END,
    RENTAL_RESERVING,
    RENTAL_RIDING,
    RENTAL_PARKING,
    RENTAL_CANCEL,
    RENTAL_PAUSE,

    REFUND_BY_MONEY,
    REFUND_BY_COUPON,

    PAYMENT_CHANNEL_TAPPAY,
    PAYMENT_CHANNEL_GOPOCKET,
} from 'constants/rental';
const paymentStatus = {
    [PAYMENT_STATUS_SUCCESS]: 'Success',
    [PAYMENT_STATUS_FAIL]: 'Failed',
    [PAYMENT_STATUS_UNPAID]: 'Unpaid',
    [PAYMENT_STATUS_PENDING]: 'Pending',
};
const rentalGroupingState = {
    [RENTAL_SIMPLIFIED_IDLE]: 'Idle',
    [RENTAL_SIMPLIFIED_RESERVING]: 'Reserving',
    [RENTAL_SIMPLIFIED_RENTING]: 'Renting',
};
const rentalSpecificState = {
    [RENTAL_END]: 'Returned',
    [RENTAL_RESERVING]: 'Reserving',
    [RENTAL_RIDING]: 'Renting',
    [RENTAL_PARKING]: 'Parking',
    [RENTAL_CANCEL]: 'Cancel',
    [RENTAL_PAUSE]: 'Pausing',
};
const rentalAction = {
    [RENTAL_ACTION_TIMEOUT]: 'Timeout',
    [RENTAL_ACTION_RESERVE]: 'Reserve',
    [RENTAL_ACTION_CANCEL]: 'Cancel',
    [RENTAL_ACTION_BEEP]: 'Beep',
    [RENTAL_ACTION_RIDE]: 'Ride',
    [RENTAL_ACTION_PARK]: 'Park',
    [RENTAL_ACTION_OPEN_TRUNK]: 'Open Trunk',
    [RENTAL_ACTION_RETURN]: 'Return',
};
const refundType = {
    [REFUND_BY_MONEY]: 'Refund',
    [REFUND_BY_COUPON]: 'Coupon',
};

const paymentChannel = {
    [PAYMENT_CHANNEL_TAPPAY]: 'TapPay Order ID',
    [PAYMENT_CHANNEL_GOPOCKET]: 'Order ID',
};

const rental = {
    'document_title': 'Rental Management',
    'rental_detail': 'Rental Detail',
    'rental_no': 'Rental No.',
    'payment_channel': paymentChannel,
    'invoice_no': 'Invoice No.',
    'subtotal': 'Subtotal',
    'billing': 'Billing',
    'discount': 'Discount',
    'charged': 'Charged',
    'area_discount': 'Location Based Promotion',
    'total_charged': 'Total Charged',
    'gogoro_rewards': 'Gogoro Rewards',
    'time': 'Time',
    'information': 'Information',
    'trip': 'Trip',
    'price': 'Amount',
    'refund': 'Refund',
    'refunded': 'Refunded',
    'allowance': 'Allowance',
    'compensated': 'Compensated',
    'compensation': 'Compensation',
    'net_soc_change': 'Net SOC Change(%)',
    'payment_status_map': paymentStatus,
    'rental_grouping_state_map': rentalGroupingState,
    'rental_specific_state_map': rentalSpecificState,
    'rental_action_map': rentalAction,
    'refund_type_map': refundType,
    'refund_failed': 'Refund Failed(%{code})',
    'refund_failed_go_rewards': 'Refund Failed',
    'partial_refund_failed': 'This payment has not collected yet which can\'t process refund at this moment',
    'change_amount': 'Change Amount',
    'original_amount': 'Orignial Amount',
    'new_amount': 'New Amount',
    'chagne_amount_failed': 'Failed to Change Amount(%{code})',
    'record_periods': 'Period',
    'battery_swap_count': 'Low SOC Battery Swap Count',
    'photo': 'Scooter Photo',
    'purchase_discount': 'Discounts with All Pass Ticket',
    'pause_duration': 'Pause Time',
    'ride_duration': 'Riding Time'
};

export default rental;
