import {
    APPROVAL_APPROVE_ID,
    APPROVAL_REVOKE_ID,
    APPROVAL_APPROVE_LIGHT_DUTY,
    APPROVAL_APPROVE_HEAVY_DUTY,
    APPROVAL_REVOKE_DRIVER_LICENSE,
    APPROVAL_KEEP_CURRENT_VALUE,
    VERIFICATION_NOT_YET,
    VERIFICATION_SUCCESS,
    VERIFICATION_DENIED_FRAUD,
    VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE,
    VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY,
    VERIFICATION_ERRO_NOT_READABLE_ID,
    VERIFICATION_VALIDITY_FALSE,
    VERIFICATION_VALIDITY_TRUE,
    VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE,
    VERIFICATION_INVALID_DOC,
    VERIFICATION_DUPLICATED_ID,
    LICENSE_TYPE_INVALID_LICENSE,
    LICENSE_TYPE_OTHER,
    LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE,
    LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE,
    COUPON_STATE_ACTIVE,
    COUPON_STATE_PAST,
    PLAN_BASIC,
    PLAN_CORPORATE,
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
    EMAIL_VERIFIED,
    EMAIL_UNVERIFIED,
    EMAIL_FORCE_VERIFY,
    ERROR_NOT_FOUND,
    ERROR_ID_DUPLICATED,
    ERROR_EMAIL_DUPLICATED,
    ERROR_BANNED_LIGHT_DUTY,
    ERROR_FORMAT_ERROR,
    ERROR_PHONE_DUPLICATED,
    OVERALL_UNVERIFIED,
    OVERALL_ALLOWED,
    OVERALL_MANDATORY_VERIFY_EMAIL,
    OVERALL_NOT_MATCH_ID,
    OVERALL_DUPLICATED_ID,
    OVERALL_FAILED_VERIFICATION,
    OVERALL_RENT_SUSPENDED,
    OVERALL_NO_BINDING_CREDITCARD,
    OVERALL_EXPIRED_PAYMENT_REQUEST,
    OVERALL_OUTSTANDING_RENTAL_PAYMENT,
    IN_PROGRESS,
    ABORTED,
    ACHIEVED,
    EXPIRED,
    TIMES_LIMIT_TYPE_PER_DAY,
    TIMES_LIMIT_TYPE_PER_MONTH,
    EASY_CARD,
    MENGO,
    PURCHASE_CANCELED,
    PURCHASE_ACTIVE,
    PURCHASE_EXPIRED,
    PURCHASE_INCOMING,
    ERROR_CODE_REFUND_0,
    ERROR_CODE_REFUND_40001,
    ERROR_CODE_REFUND_40901,
    ERROR_CODE_REFUND_40902,
    ERROR_CODE_REFUND_40903,
    ERROR_CODE_REFUND_40904,
} from 'constants/customer';

const customer = {
    'document_title': 'Customer',
    'customer_finder': 'Customer Finder',
    'customer_profile': 'Customer Profile',
    'personal_data': 'Personal Data',
    'membership': 'Membership',
    'documents_verification': 'Documents Verification',
    'edited_verification': 'Edited Verification',
    'details': 'Details',
    'user_id': 'User ID',
    'name': 'Name',
    'plan': 'Plan',
    'attributes': 'Attributes',
    'customer_no': 'Customer No.',
    'new_id': 'ID',
    'birth': 'Date of Birth',
    'id_no': 'ID No.',
    'id_approve': 'ID',
    'id_birth': 'Date of Birth on ID',
    'invalid_id_format': 'Invalid ID format',
    'driving_license': 'Driving License(Front)',
    'driving_license_back': 'Driving License(Back)',
    'live_photo': 'Live Photo',
    'license_no': 'Driving License No.',
    'license_approve': 'Driving License',
    'license_birth': 'Date of Birth on Driving License',
    'license_type': 'Driving License Type',
    'cross_check': 'Cross Check',
    'birthday': 'Date of Birth',
    'first_name/last_name': 'First Name / Last Name',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'mobile': 'Mobile No.',
    'email': 'Email',
    'join_date': 'Joint Date',
    'credit_card_no': 'Credit Card No.(Last 4 digits)',
    'associated_gopocket': 'Linked To Gogoro Account',
    'reward_point': 'Gogoro Rewards Balance',
    'reward_point_unit': 'Gogoro Smart Points',
    'reward_point_expired_time': 'Gogoro Rewards Valid Until',
    'recent_history': 'Recent Rentals',
    'coupon': 'Coupon',
    'view_documents': 'View Documents',
    'compensation': 'Compensation',
    'uploaded_doucments': 'Uploaded Documents',
    'create_time': 'Created Time',
    'valid_from': 'Valid From',
    'valid_to': 'Valid To',
    'used_time': 'Used Time',
    'corporate_type': 'Member Type',
    [`approval_id_${ APPROVAL_APPROVE_ID }`]: 'Approve',
    [`approval_id_${ APPROVAL_REVOKE_ID }`]: 'Revoke',
    [`approval_license_${ APPROVAL_APPROVE_LIGHT_DUTY }`]: 'Approve (Light or Car)',
    [`approval_license_${ APPROVAL_APPROVE_HEAVY_DUTY }`]: 'Approve (Heavy)',
    [`approval_license_${ APPROVAL_REVOKE_DRIVER_LICENSE }`]: 'Revoke',
    [`approval_${ APPROVAL_KEEP_CURRENT_VALUE }`]: 'Keep Current Value',
    [`verification_${ VERIFICATION_NOT_YET }`]: 'Not Upload Yet',
    [`verification_${ VERIFICATION_SUCCESS }`]: 'Success',
    [`verification_${ VERIFICATION_DENIED_FRAUD }`]: 'Denied',
    [`verification_${ VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE }`]: 'Unsupported Type',
    [`verification_${ VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY }`]: 'Unsupported Country',
    [`verification_${ VERIFICATION_ERRO_NOT_READABLE_ID }`]: 'Not Readable',
    [`verification_${ VERIFICATION_VALIDITY_FALSE }`]: 'Face Identification Failed',
    [`verification_${ VERIFICATION_VALIDITY_TRUE }`]: 'Face and Document Photo are NOT MATCH',
    [`verification_${ VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE }`]: 'ID No. doesn\'t match',
    [`verification_${ VERIFICATION_INVALID_DOC }`]: 'Invalid Document',
    [`verification_${ VERIFICATION_DUPLICATED_ID }`]: 'ID No. duplicated',
    'verification_failed': 'Failed',
    [`license_${ LICENSE_TYPE_INVALID_LICENSE }`]: 'Invalid License',
    [`license_${ LICENSE_TYPE_OTHER }`]: 'Light Duty Motorcycle or Car',
    [`license_${ LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE }`]: 'Heavy Duty Motorcycle',
    [`license_${ LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE }`]: 'Large Heavy Duty Motorcycle',
    [`coupon_state_${ COUPON_STATE_ACTIVE }`]: 'Active',
    [`coupon_state_${ COUPON_STATE_PAST }`]: 'Past',
    [`plan_${ PLAN_BASIC }`]: 'Basic',
    [`plan_${ PLAN_CORPORATE }`]: 'Business',
    'corporate': 'Business',
    [`corporate_type_${ CORPORATE_TYPE_NORMAL }`]: 'Normal',
    [`corporate_type_${ CORPORATE_TYPE_BASIC }`]: 'Business Account',
    [`corporate_type_${ CORPORATE_TYPE_VIP }`]: 'Business Premium',
    'contract': 'Business Plan',
    'corporate_name': 'Company Name',
    'trip_times_remaining_quota': 'Remaining Quota',
    'general': 'Business Account',
    'vip': 'Business Premium',
    'contract_period': 'Contract Period',
    'subsidy_amount': 'Spending Allowance',
    'subsidy_all': 'Full amount covered by the company',
    'subsidy_price': '$ %{count} off per trip',
    'subsidy_percent': '%{count} % off per trip',
    'subsidy_percent_max': '%{count} % off per trip, up to $ %{max} covered',
    'itinerary': 'Trip Allowance',
    'itinerary_all': 'Unlimited trips',
    'itinerary_day': '%{count} trips per day',
    'itinerary_month': '%{count} trips per month',
    'email_status': 'Email Status',
    [`email_status_${ EMAIL_VERIFIED }`]: 'Verified',
    [`email_status_${ EMAIL_UNVERIFIED }`]: 'Not Verified',
    [`email_status_${ EMAIL_FORCE_VERIFY }`]: 'Mandatory Verification',
    [`error_${ ERROR_NOT_FOUND }`]: 'Member does\'t exist',
    [`error_${ ERROR_ID_DUPLICATED }`]: 'Duplicated ID',
    [`error_${ ERROR_EMAIL_DUPLICATED }`]: 'Email is existed',
    [`error_${ ERROR_BANNED_LIGHT_DUTY }`]: 'Shall not approve this kind of license',
    [`error_${ ERROR_FORMAT_ERROR }`]: 'Invalid phone number format',
    [`error_${ ERROR_PHONE_DUPLICATED }`]: 'Duplicated phone number',
    'overall_status': 'Rental Status',
    [`overall_${ OVERALL_UNVERIFIED }`]: 'No. Doesn\'t have either ID or driver\'s license',
    [`overall_${ OVERALL_ALLOWED }`]: 'Normal',
    [`overall_${ OVERALL_MANDATORY_VERIFY_EMAIL }`]: 'No. Need to verify email address',
    [`overall_${ OVERALL_NOT_MATCH_ID }`]: 'No. ID No. doesn\'t match',
    [`overall_${ OVERALL_DUPLICATED_ID }`]: 'No. Duplicate ID number',
    [`overall_${ OVERALL_FAILED_VERIFICATION }`]: 'No. Document verification failed',
    [`overall_${ OVERALL_RENT_SUSPENDED }`]: 'Suspended',
    [`overall_${ OVERALL_NO_BINDING_CREDITCARD }`]: 'No. Didn’t bind a credit card',
    [`overall_${ OVERALL_EXPIRED_PAYMENT_REQUEST }`]: 'No. Have expired additional fee',
    [`overall_${ OVERALL_OUTSTANDING_RENTAL_PAYMENT }`]: 'No. Have outstanding rental fee',
    'release_rental_suspended': 'Enabled',
    'lockby_rental_suspended': 'Disabled',
    'enable_status_item': 'Item',
    'status_item_default': 'Please select an item',
    'customer_status': 'User Account',
    'modify_customer_status': 'Modify Status',
    'status': 'Status',
    'enable_customer_status': 'Enable this account',
    'suspended': 'Suspended',
    'nationality': 'Nationality',
    'resident': 'Resident',
    'foreigner': 'Foreigner',
    'mission': 'Mission',
    'start_time': 'Start time',
    'finish_time': 'End time',
    'progress_value': 'Progress',
    'state': 'State',
    [`mission_state_${ IN_PROGRESS }`]: 'Ongoing',
    [`mission_state_${ ABORTED }`]: 'Aborted',
    [`mission_state_${ ACHIEVED }`]: 'Completed',
    [`mission_state_${ EXPIRED }`]: 'Incomplete',
    [`remaing_quota_unit_${ TIMES_LIMIT_TYPE_PER_DAY }`]: ' / today',
    [`remaing_quota_unit_${ TIMES_LIMIT_TYPE_PER_MONTH }`]: ' / this month',
    'unlimited': 'Unlimited trips',
    'purchase_history': 'Discounts with All Pass Ticket',
    'purchase_code': 'Purchase Code',
    'discount_total_trip': 'Discount Total Trip',
    'refund_amount': 'Refund Amount',
    'pay_time': 'Purchase time',
    [`purchase_code_${ EASY_CARD }`]: 'Easy Card',
    [`purchase_code_${ MENGO }`]: 'MeN Go',
    [`purchase_state_${ PURCHASE_CANCELED }`]: 'Cancel',
    [`purchase_state_${ PURCHASE_ACTIVE }`]: 'Active',
    [`purchase_state_${ PURCHASE_EXPIRED }`]: 'Expired',
    [`purchase_state_${ PURCHASE_INCOMING }`]: 'Incoming',
    [`refund_error_${ ERROR_CODE_REFUND_40001 }`]: 'Refund price not valid',
    [`refund_error_${ ERROR_CODE_REFUND_40901 }`]: 'Purchase not found',
    [`refund_error_${ ERROR_CODE_REFUND_40902 }`]: 'Purchase already refunded',
    [`refund_error_${ ERROR_CODE_REFUND_40903 }`]: 'Purchase cannot refund',
    [`refund_error_${ ERROR_CODE_REFUND_40904 }`]:
        'This payment has not collected yet which can\'t process refund at this moment',
    [`refund_error_${ ERROR_CODE_REFUND_0 }`]: 'Oops! Something Went Wrong',
    'discount': 'Discount',
    'discount_content': '$%{count} off per trip with unlimited rides',
    'access_over_rate_limit': 'You have sent too many requests. Please try again later.',
    'unpaid_request': 'Additional Fee',
    'forbidden_license': 'Documents restricted by regulations',
};

export default customer;
