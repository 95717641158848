export const fullname = (first, last) => {
    let names = [];
    if (first && first !== null) {
        names.push(first);
    }
    if (last && last !== null) {
        names.push(last);
    }
    return names.join(' ');
};
