import {
    LOG_FULL_REFUND,
    LOG_COUPON_REFUND,
    LOG_CHANGE_AMOUNT,
    LOG_PARTIAL_REFUND,
    LOG_FORCE_RETURN,
    LOG_SEND_COMPENSATION,
    LOG_PURCHASE_REFUND,
} from 'constants/log';

import rental from './rental.js'

const log = {
    [`type_${ LOG_FULL_REFUND }`]: 'Full Refund',
    [`type_${ LOG_COUPON_REFUND }`]: 'Coupon Refund',
    [`type_${ LOG_CHANGE_AMOUNT }`]: 'Change Amount',
    [`type_${ LOG_PARTIAL_REFUND }`]: 'Partial Refund',
    [`type_${ LOG_FORCE_RETURN }`]: 'Force Return',
    [`type_${ LOG_SEND_COMPENSATION }`]: 'Send Compensation Coupon',
    [`type_${ LOG_PURCHASE_REFUND }`]: 'Discounts with All Pass Ticket Refund',
    [`description_${ LOG_FULL_REFUND }`]: 'Full Refund - <em>%{after}</em>',
    [`description_${ LOG_FULL_REFUND }_points`]: `Full Refund - <em>%{after}</em> ; ${ rental.gogoro_rewards }: - <em>%{points}</em>`,
    [`description_${ LOG_COUPON_REFUND }`]: 'Coupon Refund - <em>%{after}</em>',
    [`description_${ LOG_CHANGE_AMOUNT }`]: 'Changed Subtotal from <em>%{before}</em> to <em>%{after}</em>',
    [`description_${ LOG_PARTIAL_REFUND }`]: 'Partial Refund - <em>%{after}</em>',
    [`description_${ LOG_PARTIAL_REFUND }_points`]: `Partial Refund - <em>%{after}</em> ; ${ rental.gogoro_rewards }: - <em>%{points}</em>`,
    [`description_${ LOG_FORCE_RETURN }`]: 'Force Return and charged <em>%{after}</em>',
    [`description_${ LOG_FORCE_RETURN }_points`]: `Force Return and charged <em>%{after}</em> ; ${ rental.gogoro_rewards }: <em>%{points}</em>`,
    [`description_${ LOG_SEND_COMPENSATION }`]: 'Send Compensation Coupon - <em>%{after}</em>',
    [`description_${ LOG_PURCHASE_REFUND }`]: 'Discounts with All Pass Ticket Refund - <em>%{after}</em>',
    'search_for_modified_by_user_account': 'Input Email',
    'search_for_rental_id': 'Input Rental No.',
    'rental_id': 'Rental No.',
    'type': 'Type',
    'modified_by_user_account': 'Performed By',
    'description': 'Description',
    'create_time': 'Update Time',
    'export_finish': 'Your audit logs is being generated. We\'ll send you an email when it\'s ready to go.',
};

export default log;
