import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import serialize from 'form-serialize';
import { Row, Col, Form, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import Smart from 'components/Smart';
import FlexGroup from 'components/FlexGroup';
import Datetime from 'components/Datetime';
import Button from 'components/Form/Button';
import AuthFeature from 'components/AuthFeature';
import AuthView from 'components/AuthView';
import NavigationBar from 'components/NavigationBar';
import { Refresh } from 'components/Pagination';
import { SYSTEM_COUPON } from 'constants/routes';
import {
    AUTH_EDIT_COUPON,
    AUTH_PUBLISH_COUPON,
} from 'constants/permission';
import {
    STATUS_PUBLISH,
    STATUS_DRAFT,
} from 'constants/coupon';
import { empty } from 'helpers/util';
import permissionHandler, { ANY_ONE } from 'helpers/permission-handler';
import { convertToUTC } from 'helpers/time-handler';
import {
    toggleErrorDialog,
    fetchOneSystemCoupon,
    updateSystemCoupon,
    createSystemCoupon,
    updateDocumentTitle,
} from 'actions';
import SystemCouponDetails from './SystemCouponDetails';
import SystemCouponTimetable from './SystemCouponTimetable';

class SystemCouponForm extends React.Component {
    static propTypes = {
        oneSystemCoupon: PropTypes.shape({}),
    }

    static defaultProps = {
        oneSystemCoupon: undefined,
    }


    constructor(props) {
        super(props);

        const { match } = props;
        this.couponId = match.params.couponId;
        this.formRef = React.createRef();

        this.state = {
            processDone: false,
            viewOnly: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(updateDocumentTitle('coupon.new_document_title'));
    }

    getFormData() {
        const formData = serialize(this.formRef.current, { hash: true });
        const {
            discount_type,
            discount_value,
            promote_type,
            redeem_limit,
            valid_duration,
            status,
            valid_from,
            valid_to
        } = formData;


        return {
            ...formData,
            valid_from: convertToUTC(valid_from),
            valid_to: convertToUTC(valid_to),
            promote_type: Number(promote_type),
            discount_type: Number(discount_type),
            discount_value: Number(discount_value),
            redeem_limit: Number(redeem_limit),
            valid_duration: Number(valid_duration),
            status: Number(status),
        };
    }

    fetchData = async () => {
        if (!this.couponId) {
            return Promise.resolve();
        }

        const { dispatch } = this.props;
        const { data } = await dispatch(fetchOneSystemCoupon(this.couponId));
        const { status } = data;
        let viewOnly = true;

        if (STATUS_DRAFT === status) {
            viewOnly = !permissionHandler({ requiredList: [AUTH_EDIT_COUPON] });
        }

        this.setState({ viewOnly });
    }

    handleSave = () => {
        const isValid = this.formRef.current?.reportValidity();
        const formData = this.getFormData();
        return isValid ? this.upsertData(formData) : false;
    }

    handleStatusChanged = status => async () => {
        const { oneSystemCoupon } = this.props;
        const oneSystemCouponBody = oneSystemCoupon?.toJS() ?? {};
        const isDraft = oneSystemCouponBody.status === STATUS_DRAFT;

        if (isDraft && this.formRef.current?.reportValidity()) {
            const formData = this.getFormData();
            formData.status = status;
            return await this.upsertData(formData);
        }

        if (!isDraft) {
            return await this.upsertData({
                ...oneSystemCouponBody,
                status,
            });
        }

        return;
    }

    async upsertData(payload) {
        const { dispatch } = this.props;
        let promise = Promise.resolve();

        if (this.couponId) {
            promise = dispatch(updateSystemCoupon(this.couponId, payload));
        }
        else {
            promise = dispatch(createSystemCoupon(payload));
        }

        const { type, error } = await promise;
        if (type === 'ERROR') {
            if (error.response.data.codes && error.response.data.codes?.length !== 0) {
                dispatch(toggleErrorDialog(I18n.t(`coupon.${error.response.data.codes[0]}_error_message`)));
            }
            else {
                dispatch(toggleErrorDialog(I18n.t('coupon.wrong_api_call')));
            }
        }
        else {
            this.setState({
                processDone: true,
            });
        }
    }

    renderNavigation(oneSystemCouponBody) {
        const title = this.couponId ? oneSystemCouponBody.title : <Translate value="coupon.new_system_coupon_title" />;

        return <NavigationBar title={ title } />;
    }

    renderFunctionalButton({ status, is_used_on_promotion }) {

        function FunctionalButtons({ children }) {
            return (
                <FlexGroup start className="functional-group">
                    { children }
                </FlexGroup>
            );
        }

        let buttons = (
            <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                <FunctionalButtons>
                    <Button color="primary" key="btn-save" onClick={ this.handleSave }>
                        <Translate value="coupon.save" />
                    </Button>
                </FunctionalButtons>
            </AuthFeature>
        );

        if (!this.couponId) {
            return buttons;
        }

        switch (status) {
        case STATUS_PUBLISH:
            buttons = (
                <AuthFeature requiredList={ [AUTH_PUBLISH_COUPON] }>
                    <FlexGroup spaceBetween>
                        <Button
                            color="danger"
                            type="button"
                            onClick={ this.handleStatusChanged(STATUS_DRAFT) }
                            disabled={ is_used_on_promotion }
                        >
                            <Translate value="coupon.withdraw" />
                        </Button>
                    </FlexGroup>
                </AuthFeature>
            );
            break;
        case STATUS_DRAFT:
            buttons = (
                <AuthFeature requiredList={ [AUTH_EDIT_COUPON, AUTH_PUBLISH_COUPON] } term={ ANY_ONE }>
                    <FunctionalButtons>
                        <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                            <Button
                                color="primary"
                                key="btn-save"
                                onClick={ this.handleSave }
                            >
                                <Translate value="coupon.save" />
                            </Button>
                        </AuthFeature>
                        <AuthFeature requiredList={ [AUTH_PUBLISH_COUPON] }>
                            <Button
                                outline
                                key="btn-publish"
                                type="button"
                                onClick={ this.handleStatusChanged(STATUS_PUBLISH) }
                            >
                                <Translate value="coupon.publish" />
                            </Button>
                        </AuthFeature>
                    </FunctionalButtons>
                </AuthFeature>
            );
            break;
        default:
            buttons = null;
        }

        return buttons;
    }

    renderPublishDetails({ publisher, publish_time }) {
        return publisher && publish_time && (
            <Card tag="section">
                <p className="publish-info">
                    <Translate value="coupon.published_by" className="caption" />
                    <span className="publisher">{ publisher }</span>
                </p>
                <p className="publish-info">
                    <Translate value="coupon.published_time" className="caption" />
                    <Datetime time={ publish_time } />
                </p>
            </Card>
        );
    }

    render() {
        const { oneSystemCoupon } = this.props;
        const oneSystemCouponBody = oneSystemCoupon?.toJS() ?? {};
        const { processDone, viewOnly } = this.state;
        const responseTimeKey = oneSystemCouponBody.__responseTime?.format();
        const viewClass = classNames({
            'coupon system-coupon-form': true,
            'view-only': viewOnly,
        });

        return (
            <>
                { processDone && <Redirect to={ SYSTEM_COUPON } /> }
                { !processDone && (
                    <AuthView className={ viewClass }>
                        <Smart fetch={ this.fetchData } pauseRefresh seamless>
                            { this.renderNavigation(oneSystemCouponBody) }
                            <Form innerRef={ this.formRef }>
                                <FlexGroup spaceBetween>
                                    { this.renderFunctionalButton(oneSystemCouponBody) }
                                    <Refresh time={ oneSystemCouponBody.__responseTime } onClick={ this.fetchData } />
                                </FlexGroup>
                                { this.renderPublishDetails(oneSystemCouponBody) }
                                <Row className="form-container">
                                    <Col sm="12">
                                        { !empty(oneSystemCoupon) ? (
                                            <>
                                                <SystemCouponDetails
                                                    key={ `detail-${responseTimeKey}` }
                                                    couponBody={ oneSystemCouponBody }
                                                    viewOnly={ viewOnly }
                                                />
                                                <SystemCouponTimetable
                                                    key={ `timetable-${responseTimeKey}` }
                                                    couponBody={ oneSystemCouponBody }
                                                    viewOnly={ viewOnly }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <SystemCouponDetails />
                                                <SystemCouponTimetable />
                                            </>
                                        ) }
                                    </Col>
                                </Row>
                            </Form>
                        </Smart>
                    </AuthView>
                ) }
            </>
        );
    }
}

export default connect((state, props) => ({
    i18n: state.i18n,
    oneSystemCoupon: state.coupon.get('oneSystemCoupon')[props.match.params.couponId],
}))(SystemCouponForm);
