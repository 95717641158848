import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Button, Badge } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import { Refresh } from 'components/Pagination';
import AuthView from 'components/AuthView';
import FlexGroup from 'components/FlexGroup';
import Form from 'components/Form';
import Smart from 'components/Smart';
import AuthFeature from 'components/AuthFeature';
import NavigationBar from 'components/NavigationBar';
import Input, {
    TYPE_RADIO,
    TYPE_TEXT,
    TYPE_URL,
    TYPE_DATETIME,
} from 'components/Form/Input';
import {
    fetchOnePromotion,
    fetchUserListUploadUrl,
    updateOnePromotion,
    toggleErrorDialog,
    updateDocumentTitle,
    cleanOnePromotion,
    createOnePromotion,
} from 'actions';
import {
    AUTH_EDIT_PROMOTION_EVENT,
    AUTH_PUBLISH_PROMOTION_EVENT,
} from 'constants/permission';
import { PROMOTION_DETAIL } from 'constants/routes';
import { PROMOTE_TYPE_PROMOTION_EVENT } from 'constants/coupon';
import buildActualPath from 'helpers/build-actual-path';
import { convertToUTC } from 'helpers/time-handler';
import api from 'api';
import {
    STATUS_DRAFT,
    STATUS_SCHEDULED,
    STATUS_FINISHING,
    STATUS_FAILED,
    TYPE_GROUP,
    NO_NOTIFICATION_SOUND,
    NOTIFICATION_SOUND,
    TYPE_SEND_COUPON_AND_NOTIFICATION,
    NOTIFICATION_DEFAULT,
    NO_NOTIFICATION_DEFAULT,
    USER_IDS,
    USER_LIST_TYPE_GROUP,
    USER_FILE_TYPE_SOURCE,
    USER_FILE_TYPE_RESULT,
} from 'constants/promotion';
import { IMPORT_LIMIT_5MB, CSV_FILE } from 'constants/file';
import SystemCouponSelect from 'components/SystemCouponSelect/SystemCouponSelect';
import UserInputFile from './UserListInputFile';
import './promotion-form.scss';

class PromotionForm extends React.Component {

    static propTypes = {
        onePromotion: ImmutablePropTypes.map.isRequired,
    }

    constructor(props) {
        super(props);

        const { match } = props;
        const { params } = match;
        const { promotionId } = params;

        this.state = {
            createMode: promotionId === undefined,
            viewMode: promotionId !== undefined,
            updating: false,
            promotion: {
                id: '',
                title: '',
                type: TYPE_SEND_COUPON_AND_NOTIFICATION,
                schedule_time: undefined,
                coupon_id: '',
                notification_coupon_default: NOTIFICATION_DEFAULT,
                notification_title: '',
                notification_body: '',
                notification_sound: NO_NOTIFICATION_SOUND,
                notification_open_url: '',
                user_list_type: USER_IDS,
            },
            userListFile: undefined,
        };
    }


    componentDidMount() {
        const { createMode } = this.state;
        const { dispatch } = this.props;

        const key = createMode ? 'promotion.new_promotion' : 'promotion.view_title';
        dispatch(updateDocumentTitle(key));
    }

    componentDidUpdate(prevProps) {
        const { onePromotion: prevOnePromotion } = prevProps;
        const { onePromotion } = this.props;

        if (prevOnePromotion !== onePromotion) {
            this.setPromotionDetailState();
        }

    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(cleanOnePromotion());
    }

    setPromotionDetailState() {
        const { onePromotion } = this.props;
        const { schedule_time, ...restDetail } = onePromotion.toJS();
        this.setState({
            promotion: {
                schedule_time: moment(schedule_time),
                ...restDetail
            },
        });
    }

    openViewMode = () => {
        this.setState({
            viewMode: true
        });
        this.setPromotionDetailState();
    }

    toggleViewMode = () => {
        this.setState(state => ({
            viewMode: !state.viewMode
        }));
    }

    toggleUpdating = () => {
        this.setState(state => ({
            updating: !state.updating,
        }));
    }

    fetchData = () => {
        const { viewMode, createMode } = this.state;
        if (!viewMode) {
            return ;
        }

        if (createMode) {
            return ;
        }

        const { dispatch, match } = this.props;
        const { params } = match;
        const { promotionId } = params;

        return dispatch(fetchOnePromotion(promotionId));
    }

    handleChange = name => value => {
        switch (name) {
        case 'title':
        case 'notification_title':
        case 'notification_body':
        case 'notification_open_url':
            value = value.target.value;
            break;
        case 'coupon_id':
            ({ value } = value);
            break;
        case 'type':
        case 'notification_coupon_default':
        case 'user_list_type':
            [value] = value;
            break;
        default:
            break;
        }

        this.setState(state => {
            const { promotion } = state;

            return {
                promotion: {
                    ...promotion,
                    [name]: value,
                }
            };
        });
    }

    handleStatusChanged = status => async () => {
        const { updating } = this.state;
        if (updating) {
            return;
        }

        const { onePromotion, dispatch } = this.props;
        try {
            const promotionDetails = onePromotion.toJS();
            const { id } = promotionDetails;
            this.toggleUpdating();
            const { error } = await dispatch(updateOnePromotion(id, { status }));

            if (error) {
                throw error;
            }
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleUpdateSubmit = async e => {
        const { updating } = this.state;
        if (updating) {
            return;
        }

        const { onePromotion, dispatch } = this.props;
        const promotionDetails = onePromotion.toJS();
        const { id: promotionId } = promotionDetails;
        let {
            type,
            notification_coupon_default,
            notification_sound,
            schedule_time,
            user_list_type,
            ...restFormData
        } = e.formData;
        type = Number(type);
        notification_coupon_default = Number(notification_coupon_default);
        notification_sound = Number(notification_sound);
        user_list_type = Number(user_list_type);
        schedule_time = convertToUTC(schedule_time);
        const diffFormData = this.diff({
            type,
            notification_coupon_default,
            notification_sound,
            schedule_time,
            user_list_type,
            ...restFormData,
        }, promotionDetails);

        const empty = obj => Object.keys(obj).length === 0;

        if (empty(diffFormData)) {
            return;
        }

        try {
            this.toggleUpdating();
            // upload new user list
            if (diffFormData.user_list_file) {
                diffFormData.user_list_file = await this.uploadUserListFile();
            }

            const { error } = await dispatch(updateOnePromotion(promotionId, diffFormData));
            if (error) {
                throw error;
            }

            this.toggleViewMode();
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleCreateSubmit = async e => {
        const { dispatch, history } = this.props;
        const { updating } = this.state;
        if (updating) {
            return;
        }

        let {
            type,
            notification_coupon_default,
            notification_sound,
            schedule_time,
            user_list_type,
            ...restFormData
        } = e.formData;
        type = Number(type);
        notification_coupon_default = Number(notification_coupon_default);
        notification_sound = Number(notification_sound);
        user_list_type = Number(user_list_type);
        schedule_time = convertToUTC(schedule_time);

        try {
            this.toggleUpdating();
            const user_list_file = await this.uploadUserListFile();
            const formData = {
                ...restFormData,
                type,
                notification_coupon_default,
                notification_sound,
                user_list_type,
                schedule_time,
                user_list_file,
            };
            const { error, data } = await dispatch(createOnePromotion(formData));

            if (error) {
                throw error;
            }

            history.replace(buildActualPath(PROMOTION_DETAIL, { promotionId: data.id }));
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleImportFile = e => {
        const { dispatch } = this.props;
        const file = e.target.files[0];
        const ext = file?.name.split('.').pop();

        if (ext !== CSV_FILE) {
            dispatch(toggleErrorDialog(I18n.t('file_type_csv_warning')));
            return;
        }

        if (file?.size > IMPORT_LIMIT_5MB) {
            dispatch(toggleErrorDialog(I18n.t('file_size_5MB_warning')));
            return;
        }

        this.setState({
            userListFile: file,
        });
    }

    handleError(error) {
        const { dispatch } = this.props;
        const { code } = error.response?.data ?? {};
        const message = code !== undefined ?
            I18n.t(`promotion.error_message_${ code }`) :
            I18n.t('ticket.general_error_message');
        dispatch(toggleErrorDialog(message));
    }

    diff(formData, promotionDetails) {
        const diffFormData = Object.keys(formData).reduce((accu, key) => {
            const oldValue = promotionDetails[key];
            const newValue = formData[key];
            const isDiff = (() => {
                switch (key) {
                case 'schedule_time':
                    return !moment(newValue).isSame(oldValue);
                default:
                    return oldValue !== newValue;
                }
            })();

            if (isDiff) {
                accu[key] = newValue;
            }

            return accu;
        }, Object.create(null));

        return diffFormData;
    }

    async uploadUserListFile() {
        const { onePromotion, dispatch } = this.props;
        const { id: promotionId } = onePromotion.toJS();
        const { data, error1 } = await dispatch(fetchUserListUploadUrl(promotionId));
        if (error1) {
            throw error1;
        }

        const { url, id } = data;
        const { userListFile } = this.state;
        const { error2 } = await api.uploadFile({ url, file: userListFile, contentType: 'text/csv' });
        if (error2) {
            throw error2;
        }

        return id;
    }

    renderTitle(status, __responseTime) {
        const { viewMode } = this.state;

        return (
            <FlexGroup className="title-section" spaceBetween>
                <FlexGroup alignCenter>
                    <Translate value="promotion.details" className="details-title" tag="h4" />
                    <Status status={ status } />
                </FlexGroup>
                { viewMode && (
                    <Refresh onClick={ this.fetchData } time={ __responseTime } />
                ) }
            </FlexGroup>
        );
    }

    renderFunctionalButtons(status) {
        const { viewMode, createMode, updating } = this.state;

        function ButtonsWrapper({ children }) {
            return (
                <FlexGroup end gap>
                    { children }
                </FlexGroup>
            );
        };

        if (!viewMode) {
            return (
                <ButtonsWrapper>
                    { !createMode ? (
                        <Button type="button" color="secondary" outline onClick={ this.openViewMode }>
                            <Translate value="cancel" />
                        </Button>
                    ) : <span /> }
                    <Button type="submit" color="primary" disabled={ updating }>
                        <Translate value="save" />
                    </Button>
                </ButtonsWrapper>
            );
        }

        switch (status) {
        case STATUS_DRAFT:
            return (
                <ButtonsWrapper>
                    <AuthFeature key="publish" requiredList={ [AUTH_PUBLISH_PROMOTION_EVENT] }>
                        <Button type="button" onClick={ this.handleStatusChanged(STATUS_SCHEDULED) } outline>
                            <Translate value="promotion.publish" />
                        </Button>
                    </AuthFeature>
                    <AuthFeature key="edit" requiredList={ [AUTH_EDIT_PROMOTION_EVENT] }>
                        <Button type="button" color="primary" onClick={ this.toggleViewMode }>
                            <Translate value="promotion.edit_promotion_info" />
                        </Button>
                    </AuthFeature>
                </ButtonsWrapper>
            );
        case STATUS_SCHEDULED:
            return (
                <ButtonsWrapper>
                    <AuthFeature requiredList={ [AUTH_PUBLISH_PROMOTION_EVENT] }>
                        <Button type="button" color="danger" onClick={ this.handleStatusChanged(STATUS_DRAFT) }>
                            <Translate value="promotion.withdraw" />
                        </Button>
                    </AuthFeature>
                </ButtonsWrapper>
            );
        case STATUS_FINISHING:
        case STATUS_FAILED:
        default:
            return null;
        }
    }

    render() {
        const { onePromotion } = this.props;
        const { title: pageTitle } = onePromotion.toJS();
        const { viewMode, createMode, promotion: promotionDetails, userListFile, updating } = this.state;
        const {
            id,
            title,
            status,
            type,
            schedule_time,
            coupon_title,
            coupon_id,
            notification_coupon_default,
            notification_title,
            notification_body,
            notification_sound,
            notification_open_url,
            user_list_type,
            process_result_file,
            __responseTime
        } = promotionDetails;
        const typeOptions = TYPE_GROUP.map(type => ({
            name: `promotion.type_${ type }`,
            value: type,
            translate: true,
        }));
        const userListTypeOptions = USER_LIST_TYPE_GROUP.map(userListType => ({
            name: `promotion.user_list_type_${ userListType }`,
            value: userListType,
            translate: true,
        }));
        const notificationSoundOptions = [
            { name: 'yes', value: NOTIFICATION_SOUND, translate: true, },
            { name: 'no', value: NO_NOTIFICATION_SOUND, translate: true, },
        ];
        const notificationDefaultOptions = [
            { name: 'yes', value: NOTIFICATION_DEFAULT, translate: true, },
            { name: 'no', value: NO_NOTIFICATION_DEFAULT, translate: true, },
        ];

        return (
            <AuthView>
                <Form
                    className="promotion-form"
                    onSubmit={ createMode ? this.handleCreateSubmit : this.handleUpdateSubmit }
                    inProgress={ updating }
                    inline
                >
                    <NavigationBar sticky title={ pageTitle ?? I18n.t('promotion.new_promotion') }>
                        { this.renderFunctionalButtons(status) }
                    </NavigationBar>
                    <Smart
                        fetch={ this.fetchData }
                        pauseRefresh
                        seamless
                    >
                        { this.renderTitle(status, __responseTime) }
                        <FlexGroup className="promotion-detail" alignStretch>
                            <Input
                                name="title"
                                type={ TYPE_TEXT }
                                caption="promotion.title"
                                value={ title }
                                onChange={ this.handleChange('title') }
                                viewMode={ viewMode }
                                required
                            />
                            { !createMode && (
                                <Input
                                    name="id"
                                    type={ TYPE_TEXT }
                                    caption="promotion.promotion_id"
                                    value={ id }
                                    viewMode
                                />
                            ) }
                            <div style={ { visibility: 'hidden', height: 0 } }>
                                <Input
                                    name="type"
                                    type={ TYPE_RADIO }
                                    caption="promotion.type"
                                    value={ typeOptions }
                                    selected={ type }
                                    onChange={ this.handleChange('type') }
                                    viewMode={ viewMode }
                                    required
                                />
                            </div>
                            <Input
                                name="schedule_time"
                                type={ TYPE_DATETIME }
                                caption="promotion.schedule_time"
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm"
                                value={ schedule_time }
                                onChange={ this.handleChange('schedule_time') }
                                viewMode={ viewMode }
                                required
                            />
                            <SystemCouponSelect
                                name="coupon_id"
                                promoteType={ [PROMOTE_TYPE_PROMOTION_EVENT] }
                                selected={ {
                                    coupon_title,
                                    coupon_id
                                } }
                                onChange={ this.handleChange('coupon_id') }
                                viewMode={ viewMode }
                                required={ type === TYPE_SEND_COUPON_AND_NOTIFICATION }
                            />
                            <Input
                                name="notification_coupon_default"
                                type={ TYPE_RADIO }
                                caption="promotion.notification_coupon_default"
                                value={ notificationDefaultOptions }
                                selected={ notification_coupon_default }
                                viewMode={ viewMode }
                                onChange={ this.handleChange('notification_coupon_default') }
                                required
                            />
                            { notification_coupon_default === NO_NOTIFICATION_DEFAULT && (
                                <>
                                    <Input
                                        name="notification_title"
                                        type={ TYPE_TEXT }
                                        caption="promotion.notification_title"
                                        value={ notification_title }
                                        onChange={ this.handleChange('notification_title') }
                                        viewMode={ viewMode }
                                        required
                                    />
                                    <Input
                                        name="notification_body"
                                        type={ TYPE_TEXT }
                                        caption="promotion.notification_body"
                                        value={ notification_body }
                                        onChange={ this.handleChange('notification_body') }
                                        viewMode={ viewMode }
                                        required
                                    />
                                </>
                            ) }
                            <div style={ { visibility: 'hidden', height: 0 } }>
                                <Input
                                    name="notification_sound"
                                    type={ TYPE_RADIO }
                                    caption="promotion.notification_sound"
                                    value={ notificationSoundOptions }
                                    selected={ notification_sound }
                                    onChange={ this.handleChange('notification_sound') }
                                    viewMode={ viewMode }
                                    required
                                />
                                <Input
                                    name="notification_open_url"
                                    type={ TYPE_URL }
                                    caption="promotion.notification_open_url"
                                    value={ notification_open_url }
                                    onChange={ this.handleChange('notification_open_url') }
                                    viewMode={ viewMode }
                                />
                            </div>
                            { !viewMode && (
                                <Input
                                    name="user_list_type"
                                    type={ TYPE_RADIO }
                                    caption="promotion.user_list_type"
                                    value={ userListTypeOptions }
                                    selected={ user_list_type }
                                    onChange={ this.handleChange('user_list_type') }
                                    viewMode={ false }
                                    required
                                />
                            ) }
                            <UserInputFile
                                id={ id }
                                caption={ I18n.t(`promotion.import_user_list_type_${ user_list_type }`) }
                                name="user_list_file"
                                fileType={ USER_FILE_TYPE_SOURCE }
                                fileName={ title }
                                value={ userListFile?.name }
                                onChange={ this.handleImportFile }
                                viewMode={ viewMode }
                                required={ createMode }
                            />
                            { process_result_file && (
                                <UserInputFile
                                    id={ id }
                                    caption={ I18n.t('promotion.result') }
                                    fileType={ USER_FILE_TYPE_RESULT }
                                    fileName={ `${ title }-promote result` }
                                    viewMode
                                />
                            ) }
                        </FlexGroup>
                    </Smart>
                </Form>
            </AuthView>
        );
    }
}

function Status({ status }) {
    let color;
    switch (status) {
    case STATUS_FINISHING:
    case STATUS_SCHEDULED:
        color = 'success';
        break;
    case STATUS_FAILED:
        color = 'danger';
        break;
    case STATUS_DRAFT:
    default:
        color = 'dark';
        break;
    }
    return (
        <Badge color={ color }>
            <Translate value={ `promotion.status_${ status }` } />
        </Badge>
    );
}

Status.propTypes = {
    status: PropTypes.number
};

Status.defaultProps = {
    status: STATUS_DRAFT,
};

export default connect(state => ({
    i18n: state.i18n,
    onePromotion: state.promotion.get('onePromotion')
}))(PromotionForm);
