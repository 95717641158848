import React from 'react';
import App from 'view/App';
import Welcome from 'view/Welcome';
import Dashboard from 'view/Dashboard';
import TVMonitor from 'view/TVMonitor';
import ScooterList from 'view/ScooterList';
import ScooterMap from 'view/ScooterMap';
import ScooterHistory from 'view/ScooterHistory';
import ScooterSearch from 'view/ScooterSearch';
import RentalManagement from 'view/RentalManagement';
import RentalDetail from 'view/RentalDetail';
import CouponHome from 'view/Coupon';
import SystemCoupon from 'view/SystemCoupon';
import CouponForm from 'view/Coupon/Form';
import SystemCouponForm from 'view/SystemCoupon/SystemCouponForm';
import Ad from 'view/Ad';
import AdForm from 'view/Ad/Form';
import AdOrder from 'view/AdOrder';
import CustomerFinder from 'view/CustomerFinder';
import CustomerForm from 'view/Customer/Form';
import TicketList from 'view/Ticket';
import TicketAssignment from 'view/TicketAssignment';
import TicketSystem from 'view/TicketSystem';
import Login from 'view/Login';
import RentalAudit from 'view/RentalAudit';
import Admin from 'view/Admin';
import PromotionManagement from 'view/PromotionManagement';
import PromotionForm from 'view/PromotionForm';
import MissionManagement from 'view/MissionManagement';
import MissionForm from 'view/MissionForm';
import MissionOrder from 'view/MissionOrder';
import ViolationDetail from 'view/ViolationDetail';
import AssetManagement from 'view/AssetManagement';
import RegularMaintenance from 'view/RegularMaintenance';
import AdditionalFee from 'view/AdditionalFee';
import NewAdditionalFee from 'view/AdditionalFee/NewFee';
import AdditionalFeeDetail from 'view/AdditionalFeeDetail';

import {
    ROOT,
    MY_TICKET,
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_PROFILE,
    SCOOTER_HISTORY,
    SCOOTER_SEARCH,
    RENTAL_MANAGEMENT,
    RENTAL_DETAIL,
    LOGIN,
    COUPON_HOME,
    SYSTEM_COUPON,
    NEW_COUPON,
    NEW_SYSTEM_COUPON,
    EDIT_COUPON,
    EDIT_SYSTEM_COUPON,
    AD_MANAGEMENT,
    NEW_AD,
    EDIT_AD,
    ORDER_AD,
    CUSTOMER_FINDER,
    CUSTOMER_DETAIL,
    TICKET_LIST,
    TICKET_DETAIL,
    LOG_RENTAL,
    TICKET_ASSIGNMENT,
    ADMIN,
    PROMOTION_MANAGEMENT,
    NEW_PROMOTION,
    PROMOTION_DETAIL,
    MISSION_MANAGEMENT,
    NEW_MISSION,
    MISSION_DETAIL,
    ORDER_MISSION,
    ASSET_MANAGEMENT,
    DASHBOARD,
    TV_MONITOR,
    REGULAR_MAINTENANCE,
    ADDITIONAL_FEE,
    NEW_ADDITIONAL_FEE,
    ADDITIONAL_FEE_DETAIL,
    VIOLATION_DETAIL,
} from 'constants/routes';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LoadScript } from '@react-google-maps/api';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RTKIsLogin from 'components/RTKIsLogin';


const NoMatch = () => <h1>Not Found</h1>;
const history = createBrowserHistory();
const libraries = ['drawing', 'geometry', 'places'];
const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

export default (
    <Router>
        <HelmetProvider>
            <Provider store={ store }>
                    <RTKIsLogin />
                    <DndProvider backend={ HTML5Backend }>
                        <LoadScript
                            id="map-loader"
                            googleMapsApiKey={ REACT_APP_GOOGLE_MAP_API_KEY }
                            libraries={ libraries }
                        >
                            <App history={ history }>
                                <Switch>
                                    <Route path={ ROOT } exact component={ Welcome } />
                                    <Route path={ DASHBOARD } exact component={ Dashboard } />
                                    <Route path={ TV_MONITOR } exact component={ TVMonitor } />
                                    <Route path={ MY_TICKET } exact component={ TicketSystem } />
                                    <Route path={ SCOOTER_LIST } exact component={ ScooterList } />
                                    <Route path={ SCOOTER_MAP } exact component={ ScooterMap } />
                                    <Route path={ SCOOTER_SEARCH } exact component={ ScooterSearch } />
                                    <Route path={ SCOOTER_PROFILE } exact component={ TicketSystem } />
                                    <Route path={ SCOOTER_HISTORY } exact component={ ScooterHistory } />
                                    <Route path={ RENTAL_MANAGEMENT } exact component={ RentalManagement } />
                                    <Route path={ RENTAL_DETAIL } exact component={ RentalDetail } />
                                    <Route path={ COUPON_HOME } exact component={ CouponHome } />
                                    <Route path={ SYSTEM_COUPON } exact component={ SystemCoupon } />
                                    <Route path={ NEW_COUPON } key={ NEW_COUPON } exact component={ CouponForm } />
                                    <Route path={ NEW_SYSTEM_COUPON } exact component={ SystemCouponForm } />
                                    <Route path={ EDIT_COUPON } key={ EDIT_COUPON } exact component={ CouponForm } />
                                    <Route path={ EDIT_SYSTEM_COUPON } exact component={ SystemCouponForm } />
                                    <Route path={ AD_MANAGEMENT } exact component={ Ad } />
                                    <Route path={ NEW_AD } key={ NEW_AD } exact component={ AdForm } />
                                    <Route path={ EDIT_AD } key={ EDIT_AD } exact component={ AdForm } />
                                    <Route path={ ORDER_AD } exact component={ AdOrder } />
                                    <Route path={ PROMOTION_MANAGEMENT } exact component={ PromotionManagement } />
                                    <Route path={ NEW_PROMOTION } key={ NEW_PROMOTION } exact component={ PromotionForm } />
                                    <Route path={ PROMOTION_DETAIL } key={ PROMOTION_DETAIL } exact component={ PromotionForm } />
                                    <Route path={ MISSION_MANAGEMENT } exact component={ MissionManagement } />
                                    <Route path={ ORDER_MISSION } exact component={ MissionOrder } />
                                    <Route path={ NEW_MISSION } key={ NEW_MISSION } exact component={ MissionForm } />
                                    <Route path={ MISSION_DETAIL } key={ MISSION_DETAIL } exact component={ MissionForm } />
                                    <Route path={ CUSTOMER_FINDER } exact component={ CustomerFinder } />
                                    <Route path={ CUSTOMER_DETAIL } exact component={ CustomerForm } />
                                    <Route path={ TICKET_LIST } exact component={ TicketList } />
                                    <Route path={ TICKET_DETAIL } exact component={ TicketSystem } />
                                    <Route path={ LOG_RENTAL } exact component={ RentalAudit } />
                                    <Route path={ TICKET_ASSIGNMENT } exact component={ TicketAssignment } />
                                    <Route path={ ADMIN } exact component={ Admin } />
                                    <Route path={ LOGIN } exact component={ Login } />
                                    <Route path={ VIOLATION_DETAIL } exact component={ ViolationDetail } />
                                    <Route path={ ASSET_MANAGEMENT } exact component={ AssetManagement } />
                                    <Route path={ REGULAR_MAINTENANCE } exact component={ RegularMaintenance } />
                                    <Route path={ ADDITIONAL_FEE } exact component={ AdditionalFee } />
                                    <Route path={ NEW_ADDITIONAL_FEE } exact component={ NewAdditionalFee } />
                                    <Route path={ ADDITIONAL_FEE_DETAIL } exact component={ AdditionalFeeDetail } />
                                    <Route component={ NoMatch } />
                                </Switch>
                            </App>
                        </LoadScript>
                    </DndProvider>
            </Provider>
        </HelmetProvider>
    </Router>
);
