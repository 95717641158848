import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import { Row, Col } from 'reactstrap';
import { AD_MANAGEMENT } from 'constants/routes';
import { Redirect } from 'react-router-dom';
import AuthView from 'components/AuthView';
import AuthFeature from 'components/AuthFeature';
import FlexGroup from 'components/FlexGroup';
import permissionHandler, { ANY_ONE } from 'helpers/permission-handler';
import {
    AUTH_EDIT_BANNER,
    AUTH_PUBLISH_BANNER,
    AUTH_WITHDRAW_BANNER,
} from 'constants/permission';
import Smart from 'components/Smart';
import NavigationBar from 'components/NavigationBar';
import {
    updateDocumentTitle,
    toggleErrorDialog,
    fetchSingleAd,
    upsertSingleAd,
    clearSingleAd,
} from 'actions';
import serialize from 'form-serialize';
import { Translate, I18n } from 'react-redux-i18n';
import {
    STATUS_DISABLE,
    STATUS_PUBLISH,
    STATUS_DRAFT,
    STATUS_LIVE,
} from 'constants/ad';
import { convertToUTC } from 'helpers/time-handler';
import DetailsSection from './FormSection/Details';
import FastCopy from 'components/FastCopy';
import { COPY_TYPE_AD, getCopiedObject, hasFastCopied, clearFastCopied, copiedImgUrlToFile } from 'helpers/fast-copy';

const FunctionalButtons = ({ children }) => (
    <FlexGroup start className="functional-group">
        { children }
    </FlexGroup>
);

class Ad extends React.Component {
    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            singleAd: undefined,
            processDone: false,
            viewOnly: false,
            inProgress: false,
        };

        this.elForm = React.createRef();
        this.adId = match.params.adId;
        this.isEditiing = this.adId !== undefined;
        this.visual = undefined;
        this.elFileInput = React.createRef();
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const hasCopied = hasFastCopied(COPY_TYPE_AD);
        const copiedAd = getCopiedObject(COPY_TYPE_AD);
        const { img_url, ...copiedAdRest} = copiedAd || {};
        dispatch(updateDocumentTitle('ad.new_document_title'));

        if (!this.isEditiing && hasCopied) {
            dispatch(clearSingleAd());
            copiedImgUrlToFile(img_url, this.elFileInput);
            this.setState({
                ...this.state,
                singleAd: copiedAdRest,
            }, () => {
                clearFastCopied(COPY_TYPE_AD);
            });
        }

    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleAd());
    }

    getFormData = () => {
        const formData = serialize(this.elForm.current, { hash: true });
        const { valid_from, valid_to } = formData;
        return {
            ...formData,
            valid_from: convertToUTC(valid_from),
            valid_to: convertToUTC(valid_to),
        };
    }

    upsertData = payload => {
        const { dispatch } = this.props;
        let promise = Promise.resolve();

        this.setState({
            inProgress: true,
        });

        if (this.isEditiing) {
            promise = dispatch(upsertSingleAd({ id: this.adId, file: this.visual, payload: payload }));
        }
        else {
            promise = dispatch(upsertSingleAd({ file: this.visual, payload: payload }));
        }

        return promise.then(response => {
            const { type } = response;

            if (type === 'ERROR') {
                dispatch(toggleErrorDialog(I18n.t('ad.wrong_api_call')));
            }
            else {
                this.setState({
                    inProgress: false,
                    processDone: true,
                });
            }
        }).catch(() => {
            this.setState({
                inProgress: false,
            });
        });
    }

    handleSave = e => {
        const formData = this.getFormData();
        return this.upsertData(formData);
    }

    handleStatusChanged = status => () => {
        const { singleAd, viewOnly } = this.state;
        let promise = Promise.resolve();

        if (!viewOnly && this.elForm.current.reportValidity()) {
            const formData = this.getFormData();
            formData.status = status;
            promise = this.upsertData(formData);
        }

        if (viewOnly) {
            promise = this.upsertData({
                ...singleAd,
                status,
            });
        }
        return promise;
    }

    handleFileImport = file => {
        this.visual = file;
    }

    renderFunctionalButton({ status }) {
        const { singleAd } = this.state;
        let el = (
            <AuthFeature requiredList={ [AUTH_EDIT_BANNER] }>
                <FunctionalButtons>
                    <Button type="submit" color="primary" key="btn-save">
                        <Translate value="ad.save" />
                    </Button>
                </FunctionalButtons>
            </AuthFeature>
        );

        if (this.isEditiing) {
            switch (status) {
            case STATUS_PUBLISH:
                el = (
                    <AuthFeature requiredList={ [AUTH_PUBLISH_BANNER] }>
                        <FunctionalButtons>
                            <FastCopy type={ COPY_TYPE_AD } copyItem={ singleAd } />
                            <Button
                                color="danger"
                                type="button"
                                onClick={ this.handleStatusChanged(STATUS_DRAFT) }
                            >
                                <Translate value="ad.withdraw" />
                            </Button>
                        </FunctionalButtons>
                    </AuthFeature>
                );
                break;
            case STATUS_LIVE:
                el = (
                    <AuthFeature requiredList={ [AUTH_WITHDRAW_BANNER] }>
                        <FunctionalButtons>
                            <FastCopy type={ COPY_TYPE_AD } copyItem={ singleAd } />
                            <Button
                                color="danger"
                                type="button"
                                onClick={ this.handleStatusChanged(STATUS_DISABLE) }
                            >
                                <Translate value="ad.withdraw" />
                            </Button>
                        </FunctionalButtons>
                    </AuthFeature>
                );
                break;
            case STATUS_DRAFT:
                el = (
                    <AuthFeature
                        requiredList={ [AUTH_EDIT_BANNER, AUTH_WITHDRAW_BANNER] }
                        term={ ANY_ONE }
                    >
                        <FunctionalButtons>
                            <AuthFeature requiredList={ [AUTH_EDIT_BANNER] }>
                                <Button
                                    color="primary"
                                    key="btn-save"
                                    onClick={ this.handleStatusChanged(STATUS_DRAFT) }
                                >
                                    <Translate value="ad.save" />
                                </Button>
                            </AuthFeature>
                            <AuthFeature requiredList={ [AUTH_PUBLISH_BANNER] }>
                                <Button
                                    outline
                                    key="btn-publish"
                                    type="button"
                                    onClick={ this.handleStatusChanged(STATUS_PUBLISH) }
                                >
                                    <Translate value="ad.publish" />
                                </Button>
                            </AuthFeature>
                        </FunctionalButtons>
                    </AuthFeature>
                );
                break;
            case STATUS_DISABLE:
                el = null;
                break;
            default:
                el = (
                    <FunctionalButtons>
                        <FastCopy type={ COPY_TYPE_AD } copyItem={ singleAd } />
                    </FunctionalButtons>
                );
            }
        }

        return el;
    }

    renderNavigation(adBody) {
        const title = (this.isEditiing ?
            adBody.title :
            (
                <Translate value="ad.new_document_title" />
            )
        );
        const functionButton = this.renderFunctionalButton(adBody);

        return (
            <NavigationBar sticky title={ title }>
                { functionButton }
            </NavigationBar>
        );
    }

    render() {
        const { dispatch } = this.props;
        const { singleAd, processDone, viewOnly, inProgress } = this.state;
        const adBody = singleAd || {};
        const viewClass = classNames({
            'ad ad-form': true,
            'view-only': !permissionHandler({ requiredList: [AUTH_EDIT_BANNER] }) || viewOnly,
        });
        const fetchData = () => dispatch(fetchSingleAd(this.adId)).then(({ data = {} }) => {
            const { status } = data;
            this.setState({
                singleAd: data,
                viewOnly: STATUS_DRAFT !== status,
            });
        });

        return (
            processDone ?
                <Redirect to={ AD_MANAGEMENT } /> :
                (
                    <AuthView className={ viewClass }>
                        <Smart
                            fetch={ this.isEditiing ? fetchData : () => Promise.resolve() }
                            pauseRefresh
                            seamless
                        >
                            <Form innerRef={ this.elForm } inProgress={ inProgress } onSubmit={ this.handleSave }>
                                { this.renderNavigation(adBody) }
                                <Row className="form-container">
                                    <Col sm="12">
                                        {
                                            this.isEditiing && singleAd ? (
                                                <DetailsSection
                                                    data={ adBody }
                                                    viewOnly={ viewOnly }
                                                    onFileImport={ this.handleFileImport }
                                                    onFetch={ fetchData }
                                                />
                                            ) : (
                                                <DetailsSection
                                                    data={ singleAd }
                                                    onFileImport={ this.handleFileImport }
                                                    elFileInput={ this.elFileInput } />
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </Smart>
                    </AuthView>
                )
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(Ad);
