import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Form, Card } from 'reactstrap';
import Button from 'components/Form/Button';
import { COUPON_HOME } from 'constants/routes';
import { Redirect } from 'react-router-dom';
import AuthView from 'components/AuthView';
import AuthFeature from 'components/AuthFeature';
import FlexGroup from 'components/FlexGroup';
import { Refresh } from 'components/Pagination';
import { exportCSV } from 'helpers/util';
import permissionHandler, { ANY_ONE } from 'helpers/permission-handler';
import {
    AUTH_EDIT_COUPON,
    AUTH_PUBLISH_COUPON,
    AUTH_WITHDRAW_COUPON,
} from 'constants/permission';
import {
    CREATE_PENDING,
    CREATE_PROCESSING,
    DELETE_PENDING,
    DELETE_PROCESSING,
    DISABLE_PENDING,
    DISABLE_PROCESSING,
    TYPE_RANDOM_USER,
    TYPE_COUPON_BULK,
} from 'constants/coupon';
import { empty } from 'helpers/util';
import Smart from 'components/Smart';
import NavigationBar from 'components/NavigationBar';
import Datetime from 'components/Datetime';
import { updateDocumentTitle } from 'actions';
import {
    toggleErrorDialog,
    createCoupon,
    updateCoupon,
    fetchOneCoupon,
    clearOneCoupon,
    fetchBulkCouponCode,
} from 'actions';
import serialize from 'form-serialize';
import { Translate, I18n } from 'react-redux-i18n';
import {
    STATUS_DISABLE,
    STATUS_PUBLISH,
    STATUS_DRAFT,
    STATUS_LIVE,
} from 'constants/coupon';
import { convertToUTC } from 'helpers/time-handler';
import DetailsSection from './FormSection/Details';
import TimetableSection from './FormSection/Timetable';
import FastCopy from 'components/FastCopy';
import { COPY_TYPE_COUPON, getCopiedObject, hasFastCopied, clearFastCopied } from 'helpers/fast-copy';

import './coupon.scss';

const FunctionalButtons = ({ children }) => (
    <FlexGroup start className="functional-group">
        { children }
    </FlexGroup>
);

const processState = [
    CREATE_PROCESSING,
    DELETE_PROCESSING,
    DISABLE_PROCESSING,
];

const pendingState = [
    CREATE_PENDING,
    DELETE_PENDING,
    DISABLE_PENDING,
];

const disableState = processState.concat(pendingState);

class Coupon extends Component {
    static propTypes = {
        oneCoupon: PropTypes.shape({}),
    };

    static defaultProps = {
        oneCoupon: undefined,
    };

    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            processDone: false,
            viewOnly: false,
            initCoupon: undefined,
        };

        this.elForm = React.createRef();
        this.couponId = match.params.couponId;
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const hasCopied = hasFastCopied(COPY_TYPE_COUPON);
        const copiedCoupon = getCopiedObject(COPY_TYPE_COUPON);

        dispatch(updateDocumentTitle('coupon.new_document_title'));

        if (!this.couponId && hasCopied) {
            dispatch(clearOneCoupon());
            this.setState({
                ...this.state,
                initCoupon: copiedCoupon,
            }, () => {
                clearFastCopied(COPY_TYPE_COUPON);
            });
        }

    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearOneCoupon());
    }

    getFormData = () => {
        const formData = serialize(this.elForm.current, { hash: true });
        const {
            discount_type,
            discount_value,
            redeem_limit,
            total_limit,
            convert_duration,
            event_type,
            event_total_count,
            random_code_type,
            random_code_length,
            is_new_user_only
        } = formData;

        const { status, support_accounts, valid_from, valid_to, convert_from, convert_to } = formData;

        delete formData.valid_type;

        return {
            ...formData,
            convert_duration: convert_duration && Number(convert_duration),
            discount_type: Number(discount_type),
            discount_value: Number(discount_value),
            redeem_limit: Number(redeem_limit),
            total_limit: Number(total_limit),
            valid_from: convertToUTC(valid_from),
            valid_to: convertToUTC(valid_to),
            convert_from: convert_from && convertToUTC(convert_from),
            convert_to: convert_to && convertToUTC(convert_to),
            support_accounts: Array.isArray(support_accounts) ? support_accounts : [support_accounts],
            status: Number(status),
            event_type: Number(event_type),
            event_total_count: event_total_count ? Number(event_total_count) : 1,
            random_code_type: isNaN(random_code_type) ? TYPE_RANDOM_USER : Number(random_code_type),
            random_code_length: isNaN(random_code_length) ? 0 :  Number(random_code_length),
            is_new_user_only: Boolean(is_new_user_only)
        };
    }

    upsertData = payload => {
        const { dispatch } = this.props;
        let promise = Promise.resolve();

        if (this.couponId) {
            promise = dispatch(updateCoupon(this.couponId, payload));
        }
        else {
            promise = dispatch(createCoupon(payload));
        }

        return promise.then(({ type, error }) => {
            if (type === 'ERROR') {
                if (error.response.data.codes && error.response.data.codes.length !== 0) {
                    dispatch(toggleErrorDialog(I18n.t(`coupon.${error.response.data.codes[0]}_error_message`)));
                }
                else {
                    dispatch(toggleErrorDialog(I18n.t('coupon.wrong_api_call')));
                }
            }
            else {
                this.setState({
                    processDone: true,
                });
            }
        });
    }

    handleExport = () => {
        const { dispatch, oneCoupon } = this.props;
        const couponBody = (oneCoupon && oneCoupon.toJS()) || {};
        let promise = Promise.resolve();
        promise = dispatch(fetchBulkCouponCode(couponBody.eventId));

        return promise.then(({ data }) => {
            exportCSV(`promo_code_${couponBody.title}.csv`,
                'Promo Code,\n',
                data.promo_code
            );
        })
            .catch(err => {
                console.error(err);
                dispatch(toggleErrorDialog(I18n.t('general_error')));
            });
    }

    handleSave = () => {
        const isValid = this.elForm.current.reportValidity();
        const formData = this.getFormData();
        return isValid ? this.upsertData(formData) : false;
    }

    handleStatusChanged = status => () => {
        const { oneCoupon } = this.props;
        const couponBody = (oneCoupon && oneCoupon.toJS()) || {};
        const isDraft = couponBody.status === STATUS_DRAFT;
        let promise = Promise.resolve();

        if (isDraft && this.elForm.current.reportValidity()) {
            const formData = this.getFormData();
            formData.status = status;
            promise = this.upsertData(formData);
        }

        if (!isDraft) {
            promise = this.upsertData({
                ...oneCoupon.toJS(),
                status,
            });
        }

        return promise;
    }
    renderFunctionalButton(coupon, showExport = false) {
        const { status, bulk_task_state, bulk_task_result, event_type } = coupon;
        const isBulkDisable = disableState.includes(bulk_task_state) ?? false;
        let bulkStatusValue = '';
        if (processState.includes(bulk_task_state)) {
            bulkStatusValue = 'coupon.bulk_processing';
        }
        else if (pendingState.includes(bulk_task_state)) {
            bulkStatusValue = 'coupon.bulk_pending';
        }

        const bulkInfo = (
            <>
                { isBulkDisable && <Translate className="coupon-status" value={ bulkStatusValue } /> }
                { !isBulkDisable && (bulk_task_result && (bulk_task_result === 1 || bulk_task_result === 2)) ?
                    (
                        <Translate
                            className="coupon-status error-coupon-status"
                            value={ `coupon.bulk_result_status_${bulk_task_result}` }
                        />
                    ) :
                    null
                }
            </>
        );

        let el = (
            <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                <FunctionalButtons>
                    <Button color="primary" key="btn-save" onClick={ this.handleSave }>
                        <Translate value="coupon.save" />
                    </Button>
                </FunctionalButtons>
            </AuthFeature>
        );

        if (this.couponId) {
            switch (status) {
            case STATUS_PUBLISH:
                el = (
                    <AuthFeature requiredList={ [AUTH_PUBLISH_COUPON] }>
                        <FlexGroup spaceBetween>
                            <FunctionalButtons>
                                <FastCopy type={ COPY_TYPE_COUPON } copyItem={ coupon } />
                                <Button
                                    color="danger"
                                    type="button"
                                    disabled={ isBulkDisable }
                                    onClick={ this.handleStatusChanged(STATUS_DRAFT) }
                                >
                                    <Translate value="coupon.withdraw" />
                                </Button>
                                { bulkInfo }
                            </FunctionalButtons>
                            {
                                (event_type === TYPE_COUPON_BULK && showExport) &&
                                (
                                    <Button onClick={ this.handleExport } color="primary" type="button">
                                        <Translate value="export" />
                                    </Button>
                                )
                            }
                        </FlexGroup>
                    </AuthFeature>
                );
                break;
            case STATUS_LIVE:
                el = (
                    <AuthFeature requiredList={ [AUTH_WITHDRAW_COUPON] }>
                        <FlexGroup spaceBetween>
                            <FunctionalButtons>
                                <FastCopy type={ COPY_TYPE_COUPON } copyItem={ coupon } />
                                <Button
                                    color="danger"
                                    type="button"
                                    disabled={ isBulkDisable }
                                    onClick={ this.handleStatusChanged(STATUS_DISABLE) }
                                >
                                    <Translate value="coupon.withdraw" />
                                </Button>
                                { bulkInfo }
                            </FunctionalButtons>
                            {
                                (event_type === TYPE_COUPON_BULK && showExport) &&
                                (
                                    <Button onClick={ this.handleExport } color="primary" type="button">
                                        <Translate value="export" />
                                    </Button>
                                )
                            }
                        </FlexGroup>
                    </AuthFeature>
                );
                break;
            case STATUS_DRAFT:
                el = (
                    <AuthFeature requiredList={ [AUTH_EDIT_COUPON, AUTH_PUBLISH_COUPON] } term={ ANY_ONE }>
                        <FunctionalButtons>
                            <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                                <Button
                                    color="primary"
                                    key="btn-save"
                                    disabled={ isBulkDisable }
                                    onClick={ this.handleSave }
                                >
                                    <Translate value="coupon.save" />
                                </Button>
                            </AuthFeature>
                            <AuthFeature requiredList={ [AUTH_PUBLISH_COUPON] }>
                                <Button
                                    outline
                                    key="btn-publish"
                                    type="button"
                                    disabled={ isBulkDisable }
                                    onClick={ this.handleStatusChanged(STATUS_PUBLISH) }
                                >
                                    <Translate value="coupon.publish" />
                                </Button>
                                { bulkInfo }
                            </AuthFeature>
                        </FunctionalButtons>
                    </AuthFeature>
                );
                break;
            case STATUS_DISABLE:
                el = null;
                break;
            default:
                el = (
                    <FunctionalButtons>
                        <FastCopy type={ COPY_TYPE_COUPON } copyItem={ coupon } />
                    </FunctionalButtons>
                );
            }
        }

        return el;
    }

    renderNavigation(couponBody) {
        const title = (this.couponId ?
            couponBody.title :
            (
                <Translate value="coupon.new_document_title" />
            )
        );
        return <NavigationBar title={ title } />;
    }

    renderPublishDetails(couponBody) {
        const { publisher, publish_time } = couponBody;

        return (
            publisher && publish_time ?
                (
                    <Card tag="section">
                        <p className="publish-info">
                            <Translate value="coupon.published_by" className="caption" />
                            <span className="publisher">{ publisher }</span>
                        </p>
                        <p className="publish-info">
                            <Translate value="coupon.published_time" className="caption" />
                            <Datetime time={ publish_time } />
                        </p>
                    </Card>
                ) :
                null
        );
    }

    render() {
        const { dispatch, oneCoupon } = this.props;
        const couponBody = (oneCoupon && oneCoupon.toJS()) || {};
        const { processDone, viewOnly, initCoupon } = this.state;
        const viewClass = classNames({
            'coupon coupon-form': true,
            'view-only': viewOnly,
        });
        const fetchData = () => dispatch(fetchOneCoupon(this.couponId)).then(({ data }) => {
            const { status, bulk_task_state } = data;
            let viewOnly = true;

            if (STATUS_DRAFT === status) {
                viewOnly = !permissionHandler({ requiredList: [AUTH_EDIT_COUPON] });
            }

            if (!viewOnly) {
                viewOnly = disableState.includes(bulk_task_state);
            }

            this.setState({ viewOnly });
        });

        return (
            processDone ?
                <Redirect to={ COUPON_HOME } /> :
                (
                    <AuthView className={ viewClass }>
                        <Smart
                            fetch={ this.couponId ? fetchData : () => Promise.resolve() }
                            pauseRefresh
                            seamless
                        >
                            { this.renderNavigation(couponBody) }
                            <Form innerRef={ this.elForm }>
                                <FlexGroup className="functional-control">
                                    { this.renderFunctionalButton(couponBody, true) }
                                    { this.couponId && (
                                        <Refresh time={ couponBody.__responseTime } onClick={ fetchData } />
                                    ) }
                                </FlexGroup>
                                { this.renderPublishDetails(couponBody) }
                                <Row className="form-container">
                                    {
                                        !empty(oneCoupon) ? (
                                            <Col sm="12">
                                                <DetailsSection
                                                    key={ `detail-${couponBody.__responseTime.format()}` }
                                                    couponBody={ couponBody }
                                                    viewOnly={ viewOnly }
                                                />
                                                <TimetableSection
                                                    key={ `timetable-${couponBody.__responseTime.format()}` }
                                                    couponBody={ couponBody }
                                                    viewOnly={ viewOnly }
                                                />
                                            </Col>
                                        ) : (
                                            <Col sm="12">
                                                <DetailsSection couponBody={ initCoupon } />
                                                <TimetableSection couponBody={ initCoupon } />
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Form>
                        </Smart>
                    </AuthView>
                )
        );
    }
}

export default connect((state, ownProps) => {
    return {
        oneCoupon: state.coupon.get('oneCoupon')[ownProps.match.params.couponId],
    };
})(Coupon);
