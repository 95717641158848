import {
    LOG_FULL_REFUND,
    LOG_COUPON_REFUND,
    LOG_CHANGE_AMOUNT,
    LOG_PARTIAL_REFUND,
    LOG_FORCE_RETURN,
    LOG_SEND_COMPENSATION,
    LOG_PURCHASE_REFUND,
} from 'constants/log';

import rental from './rental.js'

const log = {
    [`type_${ LOG_FULL_REFUND }`]: '全額退款',
    [`type_${ LOG_COUPON_REFUND }`]: '折價券償還',
    [`type_${ LOG_CHANGE_AMOUNT }`]: '變更金額',
    [`type_${ LOG_PARTIAL_REFUND }`]: '部分退款',
    [`type_${ LOG_FORCE_RETURN }`]: '強制還車',
    [`type_${ LOG_SEND_COMPENSATION }`]: '發送補償券',
    [`type_${ LOG_PURCHASE_REFUND }`]: '公共運輸定期票優惠退款',
    [`description_${ LOG_FULL_REFUND }`]: '全額退款 - <em>%{after}</em>',
    [`description_${ LOG_FULL_REFUND }_points`]: `全額退款 - <em>%{after}</em> ; ${ rental.gogoro_rewards }: - <em>%{points}</em>`,
    [`description_${ LOG_COUPON_REFUND }`]: '折價券償還 - <em>%{after}</em>',
    [`description_${ LOG_CHANGE_AMOUNT }`]: '變更小計金額從 <em>%{before}</em> 到 <em>%{after}</em>',
    [`description_${ LOG_PARTIAL_REFUND }`]: '部分退款 - <em>%{after}</em>',
    [`description_${ LOG_PARTIAL_REFUND }_points`]: `部分退款 - <em>%{after}</em> ; ${ rental.gogoro_rewards }: - <em>%{points}</em>`,
    [`description_${ LOG_FORCE_RETURN }`]: '強制還車，收費 <em>%{after}</em>',
    [`description_${ LOG_FORCE_RETURN }_points`]: `強制還車，收費 <em>%{after}</em> ; ${ rental.gogoro_rewards }: <em>%{points}</em>`,
    [`description_${ LOG_SEND_COMPENSATION }`]: '發送補償券 - <em>%{after}</em>',
    [`description_${ LOG_PURCHASE_REFUND }`]: '公共運輸定期票優惠退款 - <em>%{after}</em>',
    'search_for_modified_by_user_account': '輸入 Email',
    'search_for_rental_id': '輸入租賃紀錄編號',
    'rental_id': '租賃紀錄編號',
    'type': '修改類型',
    'modified_by_user_account': '執行者',
    'description': '修改內容',
    'create_time': '修改時間',
    'export_finish': '正在產生您的稽核紀錄清單，我們會在準備好清單時寄送電子郵件給您。',
};

export default log;
