import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import debounce from 'lodash/debounce';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AuthFeature from 'components/AuthFeature';
import permissionHandler, { ANY_ONE } from 'helpers/permission-handler';
import {
    AUTH_READ_CUSTOMER_DOCUMENT_PHOTO,
    AUTH_EDIT_CUSTOMER_EMAIL,
    AUTH_EDIT_CUSTOMER_ATTRIBUTE,
    AUTH_EDIT_CUSTOMER_DOCUMENT_INFO,
    AUTH_EDIT_CUSTOMER_PHONE,
    AUTH_VIEW_CUSTOMER_EXPENSE_LIST,
    VIEW_CUSTOMER_GOV_ADDI_PURCHASE_LIST,
    AUTH_ENABLE_CUSTOMER,
    AUTH_RENT_SUSPEND,
    AUTH_VIEW_CUSTOMER_DETAIL_PRIVACY,
} from 'constants/permission';
import { Row, Col, Form } from 'reactstrap';
import Button from 'components/Form/Button';
import AuthView from 'components/AuthView';
import FlexGroup from 'components/FlexGroup';
import CollapseBanner from 'components/CollapseBanner';
import History, { RENTAL_HISTORY_CUSTOMER } from 'components/RentalHistory';
import AdditionalFeeTableList, { ADDITIONAL_FEE_CUSTOMER } from 'components/AdditionalFeeTableList';
import CouponList from './FormSection/CouponList';
import MissionList from './FormSection/MissionList';
import PurchaseList from './FormSection/PurchaseList';
import CustomerDoc from './Document';
import moment from 'moment';
import { convertToUTC } from 'helpers/time-handler';
import Pagination from 'components/Pagination';
import NavigationBar from 'components/NavigationBar';
import Modal from 'components/Modal';
import Allowance from 'components/Allowance';
import CompensationModal from './FormSection/CompensationModal';
import {
    updateDocumentTitle,
    fetchCustomerProfile,
    clearCustomerProfile,
    updateCustomerProfile,
    fetchCustomerRentalHistory,
    clearCustomerRentalHistory,
    fetchCustomerCoupon,
    fetchCustomerMission,
    clearCustomerCoupon,
    toggleErrorDialog,
    updateCustomerCoupon,
    fetchCustomerPurchaseHistory,
    togglePurchaseRefundModal,
    refundPurchase,
    fetchPaymentRequestHistory,
    clearFactorAuth,
} from 'actions';
import serialize from 'form-serialize';
import { Translate, I18n } from 'react-redux-i18n';
import DetailsSection from './FormSection/Details';
import StatusModal from './FormSection/StatusModal';
import {
    APPROVAL_APPROVE_ID,
    APPROVAL_REVOKE_ID,
    APPROVAL_KEEP_CURRENT_VALUE,
    ERROR_NOT_FOUND,
    ERROR_ID_DUPLICATED,
    ERROR_EMAIL_DUPLICATED,
    ERROR_BANNED_LIGHT_DUTY,
    ERROR_FORMAT_ERROR,
    ERROR_PHONE_DUPLICATED,
} from 'constants/customer';
import {
    FACTOR_PHONE,
    FACTOR_EMAIL,
    FACTOR_ID,
} from 'components/FactorAuth';
import {
    RENTAL_END,
    RENTAL_RESERVING,
    RENTAL_RIDING,
    RENTAL_PARKING,
    RENTAL_CANCEL,
    RENTAL_PAUSE
} from 'constants/rental';
import RefundModal from './FormSection/RefundModal';
import FactorAuth from 'components/FactorAuth';
import { ReactComponent as Eye } from 'assets/icon-eye.svg';
import { ReactComponent as EyeOff } from 'assets/icon-eye-off.svg';

import './customer.scss';

const REFRESH_INTERVAL = 1000;


class CustomerForm extends React.Component {
    static propTypes = {
        history: ImmutablePropTypes.map,
        coupon: ImmutablePropTypes.map,
        mission: ImmutablePropTypes.map,
        purchase: ImmutablePropTypes.map,
        profile: PropTypes.shape({}),
        showPurchaseModal: PropTypes.bool.isRequired,
        refundItem: PropTypes.shape({}).isRequired,
        payment: ImmutablePropTypes.map,
    };

    static defaultProps = {
        history: Map({}),
        coupon: Map({}),
        mission: Map({}),
        purchase: Map({}),
        profile: undefined,
        payment: Map({}),
    };

    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            detailError: false,
            editing: false,
            showModal: false,
            showCompensationModal: false,
            showStatusModal: false,
            showFactorModal: false,
            idScanImage: undefined,
            driverScanFrontImage: undefined,
            driverScanBackImage: undefined,
            livenessImages: undefined,
        };
        this.elForm = React.createRef();
        this.customerId = match.params.customerId;
        this.rentalFilter = [];

        this.rentalQueryPayload = {
            customer_id: this.customerId,
            rental_status: [
                RENTAL_END,
                RENTAL_RESERVING,
                RENTAL_RIDING,
                RENTAL_PARKING,
                RENTAL_CANCEL,
                RENTAL_PAUSE,
            ].filter(status => this.rentalFilter.indexOf(status) === -1),
            reserve_start_time: convertToUTC(moment().startOf('day').add(-1, 'y')),
            reserve_end_time: convertToUTC(moment().startOf('day').add(1, 'd')),
            page: 1,
            size: 20,
        };

        this.couponQueryPayload = {
            page: 1,
            size: 20,
        };

        this.missionQueryPayload = {
            page: 1,
            size: 20,
        };

        this.purchaseHistoryPayload = {
            page: 1,
            size: 20,
        };
        this.paymentRequestHistoryPayload = {
            page: 1,
            size: 20,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(updateDocumentTitle('customer.customer_profile'));
        this.handleRefresh();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearCustomerProfile());
        dispatch(clearCustomerRentalHistory());
        dispatch(clearCustomerCoupon());
        dispatch(clearFactorAuth());
    }

    getFormData = () => {
        const formData = serialize(this.elForm.current, { hash: true });
        const { profile } = this.props;
        const { email, phone, attributes } = profile.toJS();
        let dirtyFormData = {};

        if (email !== formData.email) {
            dirtyFormData.email = formData.email || '';
        }

        if ((phone !== formData.phone) &&
            permissionHandler({ requiredList: [AUTH_EDIT_CUSTOMER_PHONE] })
        ) {
            dirtyFormData.phone = formData.phone || '';
        }

        if (formData.attributes !== attributes) {
            dirtyFormData.attributes = formData.attributes || '';
        }

        if (formData.new_id) {
            dirtyFormData.new_id = formData.new_id.toUpperCase();
        }

        if (formData.birth) {
            dirtyFormData.birth = formData.birth;
        }

        if (Number(formData.id_approve) === APPROVAL_APPROVE_ID) {
            dirtyFormData.id_approve = true;
        }

        if (Number(formData.id_approve) === APPROVAL_REVOKE_ID) {
            dirtyFormData.id_approve = false;
        }

        if (Number(formData.license_approve) !== APPROVAL_KEEP_CURRENT_VALUE) {
            dirtyFormData.license_approve = Number(formData.license_approve);
        }

        return dirtyFormData;
    }

    updateProfile = payload => {
        const { dispatch } = this.props;
        let ret;

        if (Object.keys(payload).length > 0) {
            ret = dispatch(
                updateCustomerProfile(this.customerId, payload)
            ).then(
                ({ type, error, data }) => {
                    if (type === 'ERROR') {
                        const { response } = error;
                        const { data } = response;
                        const errorList = [
                            ERROR_NOT_FOUND,
                            ERROR_ID_DUPLICATED,
                            ERROR_EMAIL_DUPLICATED,
                            ERROR_BANNED_LIGHT_DUTY,
                            ERROR_FORMAT_ERROR,
                            ERROR_PHONE_DUPLICATED,
                        ];
                        const errorMessage = (
                            errorList.indexOf(data.code) === -1 ?
                                I18n.t('update_failed') :
                                I18n.t(`customer.error_${ data.code }`)
                        );

                        dispatch(toggleErrorDialog(errorMessage));
                    }
                    else {
                        const { id_scan_image, license_scan_image, license_back_scan_image, liveness_images } = data;
                        this.setState({
                            editing: false,
                            idScanImage: id_scan_image,
                            driverScanFrontImage: license_scan_image,
                            driverScanBackImage: license_back_scan_image,
                            livenessImages: liveness_images,
                        });
                    }
                }
            );
        }
        else {
            this.setState({
                editing: false,
            });
        }
        return ret;
    }

    handleSave = () => {
        const isValid = this.elForm.current.reportValidity();
        const formData = this.getFormData();

        return isValid ? this.updateProfile(formData) : false;
    }

    handleRefresh = (callback) => {
        const { dispatch, factorItems } = this.props;
        const payload = factorItems.toJS();

        dispatch(fetchCustomerProfile(this.customerId, {
            [FACTOR_PHONE]: payload[FACTOR_PHONE],
            [FACTOR_EMAIL]: payload[FACTOR_EMAIL],
            [FACTOR_ID]: payload[FACTOR_ID],
        })).then(
            ({ error, data }) => {
                if (!error) {
                    const { id_scan_image, license_scan_image, license_back_scan_image, liveness_images } = data;
                    this.setState({
                        detailError: false,
                        idScanImage: id_scan_image,
                        driverScanFrontImage: license_scan_image,
                        driverScanBackImage: license_back_scan_image,
                        livenessImages: liveness_images,
                    }, () => {
                        if (typeof callback === 'function') {
                            callback();
                        }
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        detailError: true,
                    }, () => {
                        dispatch(clearCustomerProfile());
                    });
                    console.log(error);
                }
            }
        );
    }

    toggleCollapsible = collapsible => () => {
        const { dispatch } = this.props;
        let action;
        let params;

        switch (collapsible) {
        case 'history':
            action = fetchCustomerRentalHistory;
            params = [ this.rentalQueryPayload ];
            break;
        case 'coupon':
            action = fetchCustomerCoupon;
            params = [ this.customerId, this.couponQueryPayload ];
            break;
        case 'mission':
            action = fetchCustomerMission;
            params = [ this.customerId, this.missionQueryPayload ];
            break;
        case 'purchase':
            action = fetchCustomerPurchaseHistory;
            params = [ this.customerId, this.purchaseHistoryPayload ];
            break;
        case 'payment':
            action = fetchPaymentRequestHistory;
            params = [ this.customerId, this.paymentRequestHistoryPayload ];
            break;
        default:
        }

        return dispatch(action(...params));
    };

    handleHistoryPageSelect = page => {
        this.rentalQueryPayload.page = page;

        this.toggleCollapsible('history')();
    }

    handleCouponPageSelect = page => {
        this.couponQueryPayload.page = page;

        this.toggleCollapsible('coupon')();
    }

    handleMissionPageSelect = page => {
        this.missionQueryPayload.page = page;

        this.toggleCollapsible('mission')();
    }

    handlePurchasePageSelect = page => {
        this.purchaseHistoryPayload.page = page;

        this.toggleCollapsible('purchase')();
    }

    handlePaymentRequestPageSelect = page => {
        this.paymentRequestHistoryPayload.page = page;

        this.toggleCollapsible('payment')();
    }

    closePurchaseRefundModal = () => {
        const { dispatch } = this.props;
        dispatch(togglePurchaseRefundModal({
            user_gov_addi_purchase_id: '', price: 0
        }));
    };

    openCompensationModal = () => {
        this.setState({
            showCompensationModal: true,
        });
    }

    closeCompensationModal = () => {
        this.setState({
            showCompensationModal: false,
        });
    }

    handleCompensation = payload => {
        const { dispatch } = this.props;

        return dispatch(updateCustomerCoupon(this.customerId, payload)).then(
            () => {
                this.toggleCollapsible('coupon')();
                this.closeCompensationModal();
            }
        );
    }

    handleRefundSubmit = payload => {
        const { dispatch } = this.props;
        return dispatch(refundPurchase(
            this.customerId, payload)).then(
            ({ type, error }) => {
                if (type === 'ERROR') {
                    const { code } = error.response.data;
                    const message = I18n.t(`customer.refund_error_${ code }`);

                    dispatch(toggleErrorDialog(message));
                }
                else {
                    dispatch(togglePurchaseRefundModal({
                        user_gov_addi_purchase_id: '', price: 0
                    }));
                    dispatch(fetchCustomerPurchaseHistory(...[ this.customerId, this.purchaseHistoryPayload ]));
                }
            }
        );
    }

    switchStatusModal = (status = false) => e => {
        this.setState({
            showStatusModal: status,
        });
    };

    closeFactorAuthModal = () => {
        this.setState({
            showFactorModal: false,
        });
    };
    handleUnlock = () => {
        this.setState({
            showFactorModal: true,
        });
    };

    handleDetailError = (isError) => {
        this.setState({
            ...this.state,
            detailError: isError,
        });
    };

    handleFactorIdle = () => {
        const { profile } = this.props;
        const customerProfile = (profile && profile.toJS()) || {};
        const { privacy_limited } = customerProfile;
        if (!privacy_limited) {
            this.handleRefresh(() => {
                this.handleUnlock();
            });
        }
    };

    render() {
        const {
            profile,
            history,
            coupon,
            mission,
            purchase,
            showPurchaseModal,
            refundItem,
            payment
        } = this.props;
        const customerProfile = (profile && profile.toJS()) || {};
        const { privacy_limited, id_provider, license_provider, allowance_info } = customerProfile;
        const { general, vip } = allowance_info ?? {};
        const {
            detailError,
            editing,
            showModal,
            showCompensationModal,
            showStatusModal,
            showFactorModal,
            idScanImage,
            driverScanFrontImage,
            driverScanBackImage,
            livenessImages,
        } = this.state;

        const DetailErrorMessage = () => {
            return (<p className="error">Something went wrong.</p>);
        }

        const ViewDocumentsModal = () => {
            return showModal ? (
                <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_DOCUMENT_PHOTO] }>
                    <Modal
                        className="view-documents-modal"
                        title={ I18n.t('customer.uploaded_doucments') }
                        onClose={ () => this.setState({ showModal: false }) }
                    >
                        <CustomerDoc
                            idProvider={ id_provider }
                            licenseProvider={ license_provider }
                            idScanImageLink={ idScanImage }
                            driverScanFrontImageLink={ driverScanFrontImage }
                            driverScanBackImageLink={ driverScanBackImage }
                            livePhotoListLink={ livenessImages }
                        />
                        <section className="function-button">
                            <Button
                                color="primary"
                                onClick={ () => this.setState({ showModal: false }) }
                            >
                                <Translate value="close" />
                            </Button>
                        </section>
                    </Modal>
                </AuthFeature>
            ) : null;
        };
        const historyData = history.toJS();
        const historyHeight = ((historyData.data_list || []).length + 1) * 40;
        const couponData = coupon.toJS();
        const couponHeight = ((couponData.data_list || []).length + 1) * 40;
        const missionData = mission.toJS();
        const missionHeight = ((missionData.data_list || []).length + 1) * 40;
        const purchaseData = purchase.toJS();
        const purchaseHeight = ((purchaseData.data_list || []).length + 1) * 55;
        const paymentData = payment.toJS();
        const paymentHeight = ((paymentData.data_list || []).length + 1) * 40;

        const unlockDisabled =  privacy_limited === undefined ? false : !privacy_limited;
        const noPIIMask = permissionHandler({ requiredList: [AUTH_VIEW_CUSTOMER_DETAIL_PRIVACY] });

        return (
            <AuthView className="customer customer-form">
                <Form innerRef={ this.elForm }>
                    <NavigationBar title={ this.customerId } sticky>
                        <FlexGroup className="functional-group">
                            <Button
                                outline
                                className="btn-unlock"
                                type="button"
                                onClick={ this.handleUnlock }
                                disabled={ unlockDisabled }
                            >
                                {
                                    unlockDisabled ? <EyeOff /> : <Eye />
                                }
                            </Button>
                            <AuthFeature
                                requiredList={ [
                                    AUTH_EDIT_CUSTOMER_EMAIL,
                                    AUTH_EDIT_CUSTOMER_PHONE,
                                    AUTH_EDIT_CUSTOMER_ATTRIBUTE,
                                    AUTH_EDIT_CUSTOMER_DOCUMENT_INFO,
                                ] }
                                term={ ANY_ONE }
                            >
                                <Button
                                    outline
                                    className="btn-free-coupon"
                                    type="button"
                                    onClick={ this.openCompensationModal }
                                >
                                    <Translate value="customer.compensation" />
                                </Button>
                            </AuthFeature>
                            <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_DOCUMENT_PHOTO] }>
                                <Button
                                    outline
                                    key="btn-view-documents"
                                    type="button"
                                    onClick={ () => this.setState({ showModal: true }) }
                                    disabled={ privacy_limited ?? true }
                                >
                                    <Translate value="customer.view_documents" />
                                </Button>
                            </AuthFeature>
                            <AuthFeature
                                requiredList={ [AUTH_ENABLE_CUSTOMER, AUTH_RENT_SUSPEND] }
                                term={ ANY_ONE }
                            >
                                <Button
                                    outline
                                    key="btn-save"
                                    onClick={ this.switchStatusModal(true) }
                                    disabled={ privacy_limited ?? true }
                                >
                                    <Translate value="customer.modify_customer_status" />
                                </Button>
                            </AuthFeature>
                            <AuthFeature
                                requiredList={ [
                                    AUTH_EDIT_CUSTOMER_EMAIL,
                                    AUTH_EDIT_CUSTOMER_PHONE,
                                    AUTH_EDIT_CUSTOMER_ATTRIBUTE,
                                    AUTH_EDIT_CUSTOMER_DOCUMENT_INFO,
                                ] }
                                term={ ANY_ONE }
                            >
                                {
                                    editing ? (
                                        <Button
                                            color="primary"
                                            key="btn-save"
                                            onClick={ this.handleSave }
                                            disabled={ privacy_limited ?? true }
                                        >
                                            <Translate value="save" />
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            key="btn-edit"
                                            onClick={ () => this.setState({ editing: true }) }
                                            disabled={ privacy_limited ?? true }
                                        >
                                            <Translate value="edit" />
                                        </Button>
                                    )
                                }
                            </AuthFeature>
                        </FlexGroup>
                    </NavigationBar>
                    <Row className="form-container">
                        <Col sm="12">
                            {
                                detailError ? (
                                    <DetailErrorMessage />
                                ) : (
                                    <DetailsSection
                                        onRefresh={ debounce(this.handleRefresh, REFRESH_INTERVAL) }
                                        data={ customerProfile }
                                        editing={ editing }
                                    />
                                )
                            }

                        </Col>
                    </Row>
                    {
                        !editing ? (
                            <React.Fragment>
                                <AuthFeature requiredList={ [AUTH_VIEW_CUSTOMER_EXPENSE_LIST] }>
                                    <CollapseBanner
                                        className="customer-rental-history"
                                        captionKey="customer.unpaid_request"
                                        onFetch={ this.toggleCollapsible('payment') }
                                    >
                                        <FlexGroup start>
                                            <Pagination
                                                page={ paymentData.page_index }
                                                total={ paymentData.page_count }
                                                offset={ this.paymentRequestHistoryPayload.size }
                                                onSelect={ this.handlePaymentRequestPageSelect }
                                                center={ false }
                                            />
                                        </FlexGroup>
                                        <div style={ { height: paymentHeight } }>
                                            <AdditionalFeeTableList
                                                history={ paymentData.data_list }
                                                type={ ADDITIONAL_FEE_CUSTOMER }
                                            />
                                        </div>
                                    </CollapseBanner>
                                </AuthFeature>
                                { (general !== null || vip !== null) && (
                                    <CollapseBanner captionKey="customer.contract">
                                        <Allowance
                                            item={ general }
                                        />
                                        <Allowance
                                            isVip={ true }
                                            item={ vip }
                                        />
                                    </CollapseBanner>
                                ) }
                                <CollapseBanner
                                    className="customer-rental-history"
                                    captionKey="customer.recent_history"
                                    onFetch={ this.toggleCollapsible('history') }
                                >
                                    <FlexGroup start>
                                        <Pagination
                                            page={ historyData.page_index }
                                            total={ historyData.page_count }
                                            offset={ this.rentalQueryPayload.size }
                                            onSelect={ this.handleHistoryPageSelect }
                                            center={ false }
                                        />
                                    </FlexGroup>
                                    <div style={ { height: historyHeight } }>
                                        <History
                                            history={ historyData.data_list }
                                            type={ RENTAL_HISTORY_CUSTOMER }
                                        />
                                    </div>
                                </CollapseBanner>
                                <CollapseBanner
                                    captionKey="customer.coupon"
                                    onFetch={ this.toggleCollapsible('coupon') }
                                >
                                    <FlexGroup start>
                                        <Pagination
                                            page={ couponData.page_index }
                                            total={ couponData.page_count }
                                            offset={ this.couponQueryPayload.size }
                                            onSelect={ this.handleCouponPageSelect }
                                            center={ false }
                                        />
                                    </FlexGroup>
                                    <div style={ { height: couponHeight } }>
                                        <CouponList
                                            list={ couponData.data_list }
                                        />
                                    </div>
                                </CollapseBanner>
                                <CollapseBanner
                                    captionKey="customer.mission"
                                    onFetch={ this.toggleCollapsible('mission') }
                                >
                                    <FlexGroup start>
                                        <Pagination
                                            page={ missionData.page_index }
                                            total={ missionData.page_count }
                                            offset={ this.missionQueryPayload.size }
                                            onSelect={ this.handleMissionPageSelect }
                                            center={ false }
                                        />
                                    </FlexGroup>
                                    <div style={ { height: missionHeight } }>
                                        <MissionList
                                            list={ missionData.data_list }
                                        />
                                    </div>
                                </CollapseBanner>
                                <AuthFeature requiredList={ [VIEW_CUSTOMER_GOV_ADDI_PURCHASE_LIST] }>
                                    <CollapseBanner
                                        captionKey="customer.purchase_history"
                                        onFetch={ this.toggleCollapsible('purchase') }
                                    >
                                        <FlexGroup start>
                                            <Pagination
                                                page={ purchaseData.page_index }
                                                total={ purchaseData.page_count }
                                                offset={ this.purchaseHistoryPayload.size }
                                                onSelect={ this.handlePurchasePageSelect }
                                                center={ false }
                                            />
                                        </FlexGroup>
                                        <div style={ { height: purchaseHeight } }>
                                            <PurchaseList
                                                list={ purchaseData.data_list }
                                            />
                                        </div>
                                    </CollapseBanner>
                                </AuthFeature>
                            </React.Fragment>
                        ) : null
                    }
                </Form>
                <CompensationModal
                    show={ showCompensationModal }
                    onClose={ this.closeCompensationModal }
                    onSubmit={ this.handleCompensation }
                />
                <ViewDocumentsModal />
                <StatusModal
                    show={ showStatusModal }
                    profile={ customerProfile }
                    onClose={ this.switchStatusModal(false) }
                />
                <RefundModal
                    show={ showPurchaseModal }
                    item={ refundItem }
                    onClose={ this.closePurchaseRefundModal }
                    onSubmit={ this.handleRefundSubmit }
                />
                <FactorAuth
                    customerId={ this.customerId }
                    show={ showFactorModal }
                    onClose={ this.closeFactorAuthModal }
                    onSubmit={ this.handleDetailError }
                    onIdle={ this.handleFactorIdle }
                    idleDisabled={ noPIIMask || (privacy_limited ?? true) }
                />
            </AuthView>
        );
    }
}

export default connect((state, ownProps) => ({
    i18n: state.i18n,
    profile: state.customer.get('profile')[ownProps.match.params.customerId],
    coupon: state.customer.get('coupon'),
    history: state.customer.get('history'),
    mission: state.customer.get('mission'),
    purchase: state.customer.get('purchase'),
    showPurchaseModal: state.customer.get('showPurchaseModal'),
    refundItem: state.customer.get('refundItem'),
    payment: state.customer.get('paymentHistory'),
    factorItems: state.factorAuth.get('items'),
}))(CustomerForm);
