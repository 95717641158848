/**
 * Action - Customer
 */
import api from 'api';

import {
    FETCH_CUSTOMERS,
    CLEAR_CUSTOMERS,
    FETCH_CUSTOMER_PROFILE,
    UPDATE_CUSTOMER_PROFILE,
    CLEAR_CUSTOMER_PROFILE,
    FETCH_CUSTOMER_RENTAL_HISTORY,
    CLEAR_CUSTOMER_RENTAL_HISTORY,
    FETCH_CUSTOMER_COUPON,
    CLEAR_CUSTOMER_COUPON,
    FETCH_COMPENSATION_COUPON,
    UPDATE_CUSTOMER_COUPON,
    FETCH_CUSTOMER_MISSION,
    FETCH_CUSTOMER_PURCHASE_HISTORY,
    TOGGLE_PURCHASE_REFUND_MODAL,
    PURCHASE_REFUND,
    FETCH_PAYMENT_REQUEST_HISTORY
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchCustomers = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomers(params),
        type: FETCH_CUSTOMERS,
        dispatch,
    });
};

export const fetchCustomersByFinder = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomersByFinder(params),
        type: FETCH_CUSTOMERS,
        dispatch,
    });
};

export const clearCustomers = () => dispatch => dispatch({
    type: CLEAR_CUSTOMERS,
});

export const fetchCustomerProfile = (customerId, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomerProfile(customerId, params),
        type: FETCH_CUSTOMER_PROFILE,
        dispatch,
    });
};
export const updateCustomerProfile = (id, payload) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateCustomerProfile(id, payload),
        type: UPDATE_CUSTOMER_PROFILE,
        dispatch,
    });
};
export const clearCustomerProfile = () => dispatch => dispatch({
    type: CLEAR_CUSTOMER_PROFILE,
});


export const fetchCustomerRentalHistory = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomerRentalHistory(params),
        type: FETCH_CUSTOMER_RENTAL_HISTORY,
        dispatch,
    });
};

export const clearCustomerRentalHistory = () => dispatch => dispatch({
    type: CLEAR_CUSTOMER_RENTAL_HISTORY,
});

export const fetchCustomerCoupon = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomerCoupon(id, params),
        type: FETCH_CUSTOMER_COUPON,
        dispatch,
    });
};
export const clearCustomerCoupon = () => dispatch => dispatch({
    type: CLEAR_CUSTOMER_COUPON,
});

export const fetchCompensationCoupon = () => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCompensationCoupon(),
        type: FETCH_COMPENSATION_COUPON,
        dispatch,
    });
};

export const updateCustomerCoupon = (customerId, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateCustomerCoupon(customerId, params),
        type: UPDATE_CUSTOMER_COUPON,
        dispatch,
    });
};

export const fetchCustomerMission = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomerMission(id, params),
        type: FETCH_CUSTOMER_MISSION,
        dispatch,
    });
};

export const fetchCustomerPurchaseHistory = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCustomerPurchaseHistory(id, params),
        type: FETCH_CUSTOMER_PURCHASE_HISTORY,
        dispatch,
    });
};

export const togglePurchaseRefundModal = (data) => {
    return {
        type: TOGGLE_PURCHASE_REFUND_MODAL,
        data
    };
};

export const refundPurchase = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updatePurchaseRefund(id, params),
        type: PURCHASE_REFUND,
        dispatch,
    });
};

export const fetchPaymentRequestHistory = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPaymentRequestHistory(id, params),
        type: FETCH_PAYMENT_REQUEST_HISTORY,
        dispatch,
    });
};
