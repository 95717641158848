/**
 * Define action type
 */
// general
export const ERROR = 'ERROR';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_ERROR_DIALOG = 'TOGGLE_ERROR_DIALOG';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const OPEN_CATEGORY = 'OPEN_CATEGORY';
export const CLOSE_CATEGORY = 'CLOSE_CATEGORY';
export const OPEN_PLEASE_LOGIN_DIALOG = 'OPEN_PLEASE_LOGIN_DIALOG';

// async
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_FINISHED = 'ASYNC_FINISHED';

// task
export const FETCH_MY_TASK = 'FETCH_MY_TASK';
export const CLEAR_MY_TASK = 'CLEAR_MY_TASK';

// scooter
export const FETCH_SCOOTER_LIST = 'FETCH_SCOOTER_LIST';
export const CLEAR_SCOOTER_LIST = 'CLEAR_SCOOTER_LIST';
export const FETCH_SCOOTER_HISTORY = 'FETCH_SCOOTER_HISTORY';
export const CLEAR_SCOOTER_HISTORY = 'CLEAR_SCOOTER_HISTORY';
export const FETCH_SINGLE_SCOOTER = 'FETCH_SINGLE_SCOOTER';
export const CLEAR_SINGLE_SCOOTER = 'CLEAR_SINGLE_SCOOTER';
export const REMOTE_CONTROL = 'REMOTE_CONTROL';
export const BATCH_UPDATE_SERVICE_STATE = 'BATCH_UPDATE_SERVICE_STATEUPDATE';
export const OPEN_SCOOTER_DETAIL_POPUP = 'OPEN_SCOOTER_DETAIL_POPUP';
export const FETCH_SCOOTER_RENTAL_HISTORY = 'FETCH_SCOOTER_RENTAL_HISTORY';
export const CLEAR_SCOOTER_RENTAL_HISTORY = 'CLEAR_SCOOTER_RENTAL_HISTORY';
export const FORCE_RETURN = 'FORCE_RETURN';
export const GTU_REPLACEMENT = 'GTU_REPLACEMENT';
export const FETCH_SINGLE_SCOOTER_GTU = 'FETCH_SINGLE_SCOOTER_GTU';
export const CLEAR_SINGLE_SCOOTER_GTU = 'CLEAR_SINGLE_SCOOTER_GTU';
export const FETCH_SEARCHED_GTU_LIST = 'FETCH_SEARCHED_GTU_LIST';
export const CLEAR_SEARCHED_GTU_INFO = 'CLEAR_SEARCHED_GTU_INFO';
export const FETCH_NEARBY_SCOOTERS = 'FETCH_NEARBY_SCOOTERS';
export const CLEAR_NEARBY_SCOOTERS = 'CLEAR_NEARBY_SCOOTERS';
export const FETCH_ALL_SCOOTERS = 'FETCH_ALL_SCOOTERS';
export const CLEAR_ALL_SCOOTERS = 'CLEAR_ALL_SCOOTERS';
export const FETCH_SCOOTER_RELATED_TICKETS = 'FETCH_SCOOTER_RELATED_TICKETS';
export const CLEAR_SCOOTER_RELATED_TICKETS = 'CLEAR_SCOOTER_RELATED_TICKETS';
export const FETCH_SCOOTER_MODELS = 'FETCH_SCOOTER_MODELS';
export const FIRMWARE_VERSION = 'FIRMWARE_VERSION';
export const CLEAR_FIRMWARE_VERSION = 'CLEAR_FIRMWARE_VERSION';

// user
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_RENTAL_HISTORY = 'FETCH_USER_RENTAL_HISTORY';
export const CLEAR_USER_RENTAL_HISTORY = 'CLEAR_USER_RENTAL_HISTORY';
export const FETCH_TICKET_FLOW_LIST = 'FETCH_TICKET_FLOW_LIST';

// customer
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE';
export const UPDATE_CUSTOMER_PROFILE = 'UPDATE_CUSTOMER_PROFILE';
export const CLEAR_CUSTOMER_PROFILE = 'CLEAR_CUSTOMER_PROFILE';
export const FETCH_CUSTOMER_RENTAL_HISTORY = 'FETCH_CUSTOMER_RENTAL_HISTORY';
export const CLEAR_CUSTOMER_RENTAL_HISTORY = 'CLEAR_CUSTOMER_RENTAL_HISTORY';
export const FETCH_CUSTOMER_COUPON = 'FETCH_CUSTOMER_COUPON';
export const CLEAR_CUSTOMER_COUPON = 'CLEAR_CUSTOMER_COUPON';
export const FETCH_COMPENSATION_COUPON = 'FETCH_COMPENSATION_COUPON';
export const UPDATE_CUSTOMER_COUPON = 'UPDATE_CUSTOMER_COUPON';
export const FETCH_CUSTOMER_MISSION = 'FETCH_CUSTOMER_MISSION';
export const FETCH_CUSTOMER_PURCHASE_HISTORY = 'FETCH_CUSTOMER_PURCHASE_HISTORY';
export const TOGGLE_PURCHASE_REFUND_MODAL = 'TOGGLE_PURCHASE_REFUND_MODAL';
export const PURCHASE_REFUND = 'PURCHASE_REFUND';
export const FETCH_PAYMENT_REQUEST_HISTORY = 'FETCH_PAYMENT_REQUEST_HISTORY';

// rental
export const FETCH_RENTAL_HISTORY = 'FETCH_RENTAL_HISTORY';
export const CLEAR_RENTAL_HISTORY = 'CLEAR_RENTAL_HISTORY';
export const FETCH_RENTAL_DETAIL = 'FETCH_RENTAL_DETAIL';
export const CLEAR_RENTAL_DETAIL = 'CLEAR_RENTAL_DETAIL';
export const FETCH_REFUND_COUPON = 'FETCH_REFUND_COUPON';
export const CLEAR_REFUND_COUPON = 'CLEAR_REFUND_COUPON';
export const REFUND = 'REFUND';
export const FETCH_RETURN_PHOTO_URL = 'FETCH_RETURN_PHOTO_URL';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const DELETE_FRF_COOKIE = 'DELETE_FRF_COOKIE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_PERMISSION = 'REFRESH_PERMISSION';
export const FETCH_OP_USERS = 'FETCH_OP_USERS';

// ads
export const FETCH_AD_LIST = 'FETCH_AD_LIST';
export const CLEAR_AD_LIST = 'CLEAR_AD_LIST';
export const FETCH_SINGLE_AD = 'FETCH_SINGLE_AD';
export const UPSERT_SINGLE_AD = 'UPSERT_SINGLE_AD';
export const CLEAR_SINGLE_AD = 'CLEAR_SINGLE_AD';
export const FETCH_ORDERABLE_AD_LIST = 'FETCH_ORDERABLE_AD_LIST';
export const ORDER_AD_LIST = 'ORDER_AD_LIST';
export const UPDATE_AD_ORDER = 'UPDATE_AD_ORDER';

// document meta
export const UPDATE_DOCUMENT_TITLE = 'UPDATE_DOCUMENT_TITLE';

// coupon
export const FETCH_COUPON_LIST = 'FETCH_COUPON_LIST';
export const FETCH_ONE_COUPON = 'FETCH_ONE_COUPON';
export const CREATE_COUPON = 'CREATE_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const FETCH_ACCOUNT_TYPE = 'FETCH_ACCOUNT_TYPE';
export const FETCH_BULK_COUPON_CODE = 'FETCH_BULK_COUPON_CODE';
export const FETCH_SYSTEM_COUPON_LIST = 'FETCH_SYSTEM_COUPON_LIST';
export const FETCH_ONE_SYSTEM_COUPON = 'FETCH_ONE_SYSTEM_COUPON';
export const UPDATE_SYSTEM_COUPON = 'UPDATE_SYSTEM_COUPON';
export const CREATE_SYSTEM_COUPON = 'CREATE_SYSTEM_COUPON';

// ticket
export const CREATE_TICKETS = 'CREATE_TICKETS';
export const UPDATE_ONE_TICKET = 'UPDATE_ONE_TICKET';
export const CLEAN_ONE_TICKET = 'CLEAN_ONE_TICKET';
export const UPDATE_MANY_TICKET = 'UPDATE_MANY_TICKET';
export const FETCH_TICKET_LIST = 'FETCH_TICKET_LIST';
export const FETCH_ONE_TICKET = 'FETCH_ONE_TICKET';
export const FETCH_ALL_UNCLOSED_TICKET = 'FETCH_ALL_UNCLOSED_TICKET';
export const FETCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const INIT_ASSIGNMENTS = 'INIT_ASSIGNMENTS';

// map
export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_VMS = 'FETCH_VMS';
export const FOOTER_CONTENT = 'FOOTER_CONTENT';
export const SELECT_SCOOTER = 'SELECT_SCOOTER';
export const GET_VIP_LAYER = 'GET_VIP_LAYER';
export const SELECT_VIP_LAYER = 'SELECT_VIP_LAYER';
export const CLEAR_VIP_LAYER = 'CLEAR_VIP_LAYER';

// geo location
export const GET_GEOLOCATION = 'GET_GEOLOCATION';
export const CLEAR_GEOLOCATION = 'CLEAR_GEOLOCATION';
export const ERROR_GEOLOCATION = 'ERROR_GEOLOCATION';

// log
export const FETCH_RENTAL_LOG = 'FETCH_RENTAL_LOG';
export const EXPORT_RENTAL_LOG = 'EXPORT_RENTAL_LOG';
export const CLOSE_EXPORT = 'CLOSE_EXPORT';

// admin
export const FETCH_FLEET_ADMIN_USERS = 'FETCH_FLEET_ADMIN_USERS';
export const FETCH_FRANCHISEE_LIST = 'FETCH_FRANCHISEE_LIST';
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const UPDATE_FLEET_ADMIN_USER = 'UPDATE_FLEET_ADMIN_USER';
export const UPDATE_MANY_FLEET_ADMIN_USERS = 'UPDATE_MANY_FLEET_ADMIN_USERS';
export const NEW_FLEET_USER = 'NEW_FLEET_USER';

// promotion
export const FETCH_PROMOTION_LIST = 'FETCH_PROMOTION_LIST';
export const FETCH_ONE_PROMOTION = 'FETCH_ONE_PROMOTION';
export const UPDATE_ONE_PROMOTION = 'UPDATE_ONE_PROMOTION';
export const CLEAN_ONE_PROMOTION = 'CLEAN_ONE_PROMOTION';
export const CREATE_ONE_PROMOTION = 'CREATE_ONE_PROMOTION';

// violation
export const IMPORT_VIOLATION_LIST = 'IMPORT_VIOLATION_LIST';
export const GET_VIOLATION_DETAIL = 'GET_VIOLATION_DETAIL';
export const CLEAR_VIOLATION_LIST = 'CLEAR_VIOLATION_LIST';

// mission
export const FETCH_MISSION_LIST = 'FETCH_MISSION_LIST';
export const FETCH_ONE_MISSION = 'FETCH_ONE_MISSION';
export const CLEAN_ONE_MISSION = 'CLEAN_ONE_MISSION';
export const UPDATE_ONE_MISSION = 'UPDATE_ONE_MISSION';
export const FETCH_ORDERABLE_MISSION_LIST = 'FETCH_ORDERABLE_MISSION_LIST';
export const ORDER_MISSION_LIST = 'ORDER_MISSION_LIST';
export const UPDATE_MISSION_ORDER = 'UPDATE_MISSION_ORDER';

// asset
export const FETCH_ASSET_LIST = 'FETCH_ASSET_LIST';
export const CONNECTED_DEVICES = 'CONNECTED_DEVICES';

// dashboard
export const FETCH_OVERALL_STATISTICS = 'FETCH_OVERALL_STATISTICS';
export const FETCH_DOTS_STATISTICS = 'FETCH_DOTS_STATISTICS';

// tvMonitor
export const FETCH_TV_MONITOR_STATISTICS = 'FETCH_TV_MONITOR_STATISTICS';

// regular maintenance
export const FETCH_RM_SCOOTER_LIST = 'FETCH_RM_SCOOTER_LIST';

// payment request
export const FETCH_PAYMENT_REQUEST_LIST = 'FETCH_PAYMENT_REQUEST_LIST';
export const FETCH_PAYMENT_REQUEST_DETAIL = 'FETCH_PAYMENT_REQUEST_DETAIL';
export const FETCH_PAYMENT_RENTAL_HISTORY = 'FETCH_PAYMENT_RENTAL_HISTORY';
export const CLEAR_PAYMENT_RENTAL_HISTORY = 'CLEAR_PAYMENT_RENTAL_HISTORY';
export const NEW_PAYMENT_REQUEST = 'NEW_PAYMENT_REQUEST';
export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';
export const PAYMENT_REFUND = 'PAYMENT_REFUND';
// cities
export const FETCH_CITIES = 'FETCH_CITIES';

// factor authentication
export const PUT_FACTOR_AUTH = 'PUT_FACTOR_AUTH';
export const CLEAR_FACTOR_AUTH = 'CLEAR_FACTOR_AUTH';
export const CLEAR_AND_PUT_AUTH = 'CLEAR_AND_PUT_AUTH';
